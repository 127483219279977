<template>
  <div v-title :data-title="$route.name">
    <div
      v-if="offsetwidth > 850"
      style="
        width: 100%;
        overflow: hidden;
        position: relative;
        min-width: 1349px;
        margin-top: 65px;
      "
    >
      <img
        style="width: 100%"
        src="../assets/imgthree/PC/2产品介绍1.jpg"
        alt=""
      />
      <img
        style="width: 100%"
        src="../assets/imgthree/PC/2我们的产品.jpg"
        alt=""
      />
      <div class="image-container">
        <!-- 第一个图片和按钮 -->
        <div class="image-wrapper left">
          <img
            class="image-item"
            src="../assets/imgthree/PC/2产品介绍170.jpg"
            alt=""
          />
          <button class="image-button" @click="gotoproductcentent('170')">
            MORE INFO
          </button>
        </div>

        <!-- 第二个图片和按钮 -->
        <div class="image-wrapper middle">
          <img
            class="image-item"
            src="../assets/imgthree/PC/2产品介绍200.jpg"
            alt=""
          />
          <button class="image-button" @click="gotoproductcentent('200')">
            MORE INFO
          </button>
        </div>

        <!-- 第三个图片和按钮 -->
        <div class="image-wrapper right">
          <img
            class="image-item"
            src="../assets/imgthree/PC/2产品介绍230.jpg"
            alt=""
          />
          <button class="image-button" @click="gotoproductcentent('230')">
            MORE INFO
          </button>
        </div>
      </div>
      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/PC/底部PC.jpg"
          alt=""
        />
        <div>
          <div v-if="offsetwidth < 1500">
            <span
              style="
                position: absolute;
                top: 43.1%;
                left: 45.4%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 12px !important;
              "
              @click="goto('PRODUCTS')"
              >PRODUCTS</span
            >
            <span
              style="
                position: absolute;
                top: 48.8%;
                left: 44.9%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 12px !important;
              "
              @click="goto('GALLERY')"
              >GALLERY</span
            >
            <span
              style="
                position: absolute;
                top: 53.8%;
                left: 45.7%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                font-size: 12px !important;
                color: white;
              "
              @click="goto('ContactUs')"
              >CONTACT US</span
            >
            <span
              style="
                position: absolute;
                top: 59%;
                left: 45.3%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                font-size: 12px !important;
                color: white;
              "
              @click="goto('BOOKNOW')"
              >BOOK NOW</span
            >
            <!-- <span
            style="
              position: absolute;
              top: 63.9%;
              left: 46.4%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
             @click="goto('privacyPolicy')"
            >PRIVACY POLICY</span
          > -->
            <span
              style="
                position: absolute;
                top: 43%;
                left: 58.2%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                font-size: 12px !important;
                color: white;
              "
              @click="goto('AIR')"
              >AIR CONDITIONERS & REBATE</span
            >
          </div>
          <div v-else>
            <span
              style="
                position: absolute;
                top: 43.1%;
                left: 44.9%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 12px !important;
              "
              @click="goto('PRODUCTS')"
              >PRODUCTS</span
            >
            <span
              style="
                position: absolute;
                top: 48.8%;
                left: 44.5%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 12px !important;
              "
              @click="goto('GALLERY')"
              >GALLERY</span
            >
            <span
              style="
                position: absolute;
                top: 53.8%;
                left: 45.1%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                font-size: 12px !important;
                color: white;
              "
              @click="goto('ContactUs')"
              >CONTACT US</span
            >
            <span
              style="
                position: absolute;
                top: 59%;
                left: 44.9%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                font-size: 12px !important;
                color: white;
              "
              @click="goto('BOOKNOW')"
              >BOOK NOW</span
            >
            <!-- <span
            style="
              position: absolute;
              top: 63.9%;
              left: 45.7%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
             @click="goto('privacyPolicy')"
            >PRIVACY POLICY</span
          > -->
            <span
              style="
                position: absolute;
                top: 43%;
                left: 56.8%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                font-size: 12px !important;
                color: white;
              "
              @click="goto('AIR')"
              >AIR CONDITIONERS & REBATE</span
            >
          </div>
        </div>
        <!-- <img
          style="
            width: 20%;
            position: absolute;
            top: 60%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          src="../assets/imgthree/PCNEW/BookNowButton.png"
          alt=""
        /> -->
      </div>
    </div>

    <div
      v-else
      style="
        width: 100%;
        border: 1px solid #f8f9fa;
        overflow: hidden;
        position: relative;
        margin-top: 65px;
        background-color: white;
      "
    >
      <img style="width: 100%" src="../assets/imgthree/H5/2产品-1.jpg" alt="" />

      <img
        style="width: 100%"
        src="../assets/imgthree/H5/2我们的产品.jpg"
        alt=""
      />
      <div style="position: relative">
        <img
          style="width: 90%"
          src="../assets/imgthree/H5/3产品170.jpg"
          alt=""
        />
        <div>
          <button
            style="
              position: absolute;
              top: 90%;
              left: 50%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              padding: 12px 24px;
              width: 200px;
              background-color: rgb(105, 154, 255);
              color: white;
              border: none;
              border-radius: 4px;
              font-size: 17px;
              text-align: center;
            "
            @click="gotoproductcentent('170')"
          >
            MORE INFO
          </button>
        </div>
      </div>
      <div style="position: relative">
        <img
          style="width: 90%; margin-top: 30px"
          src="../assets/imgthree/H5/3产品200.jpg"
          alt=""
        />
        <div>
          <button
            style="
              position: absolute;
              top: 90%;
              left: 50%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              padding: 12px 24px;
              width: 200px;
              background-color: rgb(105, 154, 255);
              color: white;
              border: none;
              border-radius: 4px;
              font-size: 17px;
              text-align: center;
            "
            @click="gotoproductcentent('200')"
          >
            MORE INFO
          </button>
        </div>
      </div>
      <div style="position: relative">
        <img
          style="width: 90%; margin-top: 30px; margin-bottom: 30px"
          src="../assets/imgthree/H5/3产品230.jpg"
          alt=""
        />
        <div>
          <button
            style="
              position: absolute;
              top: 86%;
              left: 50%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              padding: 12px 24px;
              width: 200px;
              background-color: rgb(105, 154, 255);
              color: white;
              border: none;
              border-radius: 4px;
              font-size: 17px;
              text-align: center;
            "
            @click="gotoproductcentent('230')"
          >
            MORE INFO
          </button>
        </div>
      </div>
      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/H5/底部H5.jpg"
          alt=""
        />
        <div>
          <span
            style="
              position: absolute;
              top: 43.5%;
              left: 15%;
              cursor: pointer;
              font-weight: 700;
              transform: translate(-50%, -50%);
              color: white;
            "
            @click="goto('PRODUCTS')"
            >PRODUCTS</span
          >
          <span
            style="
              position: absolute;
              top: 46%;
              left: 13.5%;
              cursor: pointer;
              font-weight: 700;
              transform: translate(-50%, -50%);
              color: white;
            "
            @click="goto('GALLERY')"
            >GALLERY</span
          >
          <span
            style="
              position: absolute;
              top: 48.4%;
              left: 16.5%;
              cursor: pointer;
              font-weight: 700;
              transform: translate(-50%, -50%);
              color: white;
            "
            @click="goto('ContactUs')"
            >CONTACT US</span
          >
          <span
            style="
              position: absolute;
              top: 50.6%;
              left: 15.2%;
              cursor: pointer;
              font-weight: 700;
              transform: translate(-50%, -50%);
              color: white;
            "
            @click="goto('BOOKNOW')"
            >BOOK NOW</span
          >
          <!-- <span
            style="
                  position: absolute;
    top: 53.1%;
    left: 19.8%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
             @click="goto('privacyPolicy')"
            >PRIVACY POLICY</span
          > -->
          <span
            style="
              position: absolute;
              top: 71%;
              left: 30%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              font-weight: 700;
              color: white;
            "
            @click="goto('AIR')"
            >AIR CONDITIONERS & REBATE</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  mounted() {
    // window.fbq("track", "ViewContent", {
    //   content_type: "Contact Us",
    //   content_ids: ["10005"],
    //   content_name: "Contact Us",
    //   content_category: "Contact Us",
    // });
  },
  methods: {
    gotoproductcentent(name) {
      this.$router.push("/Productscontent").then(() => {
        window.scrollTo(0, 0); // 滚动到顶部
      });
      window.sessionStorage.setItem("pro", name);
      // this.$router.push({ name: "Productscontent", params: { pro: name } });
    },
    goto(name) {
      if (name == "PRODUCTS" || name == "AIR") {
        this.$router.push("/Products");
      } else if (name == "GALLERY") {
        this.$router.push("/ProjectGallery");
      } else if (name == "ContactUs") {
        this.$router.push("/ContactUscopy");
      } else if (name == "BOOKNOW") {
        this.$router.push("/CustomerDetails");
        // window.open("https://smartuser.com.au/customerdetails/");
        // this.$router.push("/ProjectGallery");
      } else if (name == "privacyPolicy") {
        this.$router.push("/privacyPolicy");
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  justify-content: center; /* 整体居中 */
  background-color: white;
  margin-bottom: 20px;
  position: relative;
}

.image-wrapper {
  position: relative; /* 使按钮相对于图片定位 */
  width: 30%; /* 设置图片宽度 */
  margin: 0 1%; /* 图片之间的间距 */
}

.image-item {
  width: 100%;
  display: block; /* 确保图片是块级元素 */
}

.image-button {
  position: absolute; /* 绝对定位 */
  bottom: 40px; /* 增加按钮距离图片底部的距离 */
  left: 50%; /* 水平居中 */
  transform: translateX(-50%); /* 水平居中 */
  padding: 12px 24px; /* 增加按钮内边距，使按钮更大 */
  width: 200px; /* 设置按钮宽度 */
  background-color: rgb(105, 154, 255);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 17px;
  text-align: center; /* 文字居中 */
}

.image-button:hover {
  background-color: #0056b3; /* 鼠标悬停效果 */
}

.image-wrapper.left {
  margin-left: 10%; /* 第一个图片距离左侧的距离 */
}

.image-wrapper.right {
  margin-right: 10%; /* 第三个图片距离右侧的距离 */
}
</style>
