<template>
  <div v-title :data-title="$route.name">
    
    <div
      v-if="offsetwidth > 850"
      style="
        width: 100%;
        overflow: hidden;
        position: relative;
        min-width: 1349px;
        margin-top: 65px;
      "
    >
      <img
        style="width: 100%"
        src="../assets/imgthree/PCNEW/titlebj.png"
        alt=""
      />
      <div style="width: 100%">
        <el-carousel
          :height="imgHeight"
          trigger="click"
          :interval="6000"
          arrow="always"
        >
          <el-carousel-item v-for="(item, index) in images" :key="index">
            <img
              ref="imgHeight"
              :src="item.idview"
              alt="Nodata"
              style="width: 100%"
              @load="imgLoad"
            />
            <div></div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/PCNEW/欢迎背景PC.jpg"
          alt=""
        />

        <img
          style="
            width: 12%;
            position: absolute;
            top: 82%;
            left: 45%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="gotoAboutus()"
          src="../assets/imgthree/PCNEW/ReadMoreButton.png"
          alt=""
        />
      </div>

      <img
        style="width: 100%"
        src="../assets/imgthree/PCNEW/Homepro.jpg"
        alt=""
      />
      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/PCNEW/蓝色背景.jpg"
          alt=""
        />

        <img
          style="
            width: 20%;
            position: absolute;
            top: 60%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="gotobooknow()"
          src="../assets/imgthree/PCNEW/BookNowButton.png"
          alt=""
        />
      </div>

          <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/PC/底部PC.jpg"
          alt=""
        />
        <div>
         <div v-if="offsetwidth<1500">
        <span
            style="
              position: absolute;
              top: 43.1%;
              left: 45.4%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              color:white;font-size:12px !important
            "
            @click="goto('PRODUCTS')"
            >PRODUCTS</span
          >
          <span
            style="
              position: absolute;
              top: 48.8%;
              left: 44.9%;
              cursor: pointer;
              transform: translate(-50%, -50%);
                color:white;
                font-size:12px !important
            "
            @click="goto('GALLERY')"
            >GALLERY</span
          >
          <span
            style="
              position: absolute;
              top: 53.8%;
             left: 45.7%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
            @click="goto('ContactUs')"
            >CONTACT US</span
          >
          <span
            style="
              position: absolute;
              top: 59%;
             left: 45.3%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
              @click="goto('BOOKNOW')"
            >BOOK NOW</span
          >
          <!-- <span
            style="
              position: absolute;
              top: 63.9%;
              left: 46.4%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
             @click="goto('privacyPolicy')"
            >PRIVACY POLICY</span
          > -->
            <span
            style="
              position: absolute;
              top: 43%;
              left: 58.2%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
             @click="goto('AIR')"
            >AIR CONDITIONERS & REBATE</span
          ></div>
          <div v-else>
   <span
            style="
              position: absolute;
              top: 43.1%;
              left: 44.9%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              color:white;font-size:12px !important
            "
            @click="goto('PRODUCTS')"
            >PRODUCTS</span
          >
          <span
            style="
              position: absolute;
              top: 48.8%;
              left: 44.5%;
              cursor: pointer;
              transform: translate(-50%, -50%);
                color:white;
                font-size:12px !important
            "
            @click="goto('GALLERY')"
            >GALLERY</span
          >
          <span
            style="
              position: absolute;
              top: 53.8%;
             left: 45.1%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
            @click="goto('ContactUs')"
            >CONTACT US</span
          >
          <span
            style="
              position: absolute;
              top: 59%;
             left: 44.9%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
              @click="goto('BOOKNOW')"
            >BOOK NOW</span
          >
          <!-- <span
            style="
              position: absolute;
              top: 63.9%;
              left: 45.7%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
             @click="goto('privacyPolicy')"
            >PRIVACY POLICY</span
          > -->
            <span
            style="
              position: absolute;
              top: 43%;
              left: 56.8%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
            @click="goto('AIR')"
            >AIR CONDITIONERS & REBATE</span
          >
          </div>
        </div>
        <!-- <img
          style="
            width: 20%;
            position: absolute;
            top: 60%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          src="../assets/imgthree/PCNEW/BookNowButton.png"
          alt=""
        /> -->
      </div>
      
    </div>
    <div
      v-else
      style="
        width: 100%;
        border: 1px solid #f8f9fa;
        overflow: hidden;
        position: relative;
        margin-top: 65px;
      "
    >
       <img
        style="width: 100%"
        src="../assets/imgthree/H5NEW/titlebj.png"
        alt=""
      />
      <div style="width: 100%">
        <el-carousel
          :height="imgHeight"
          trigger="click"
          :interval="6000"
          arrow="always"
        >
          <el-carousel-item v-for="(item, index) in imagesh5" :key="index">
            <img
              ref="imgHeight"
              :src="item.idview"
              alt="Nodata"
              style="width: 100%"
              @load="imgLoad"
            />
            <div></div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/H5NEW/欢迎H5.jpg"
          alt=""
        />

        <img
          style="
            width: 40%;
            position: absolute;
            top: 91.5%;
            left: 25%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="gotoAboutus()"
          src="../assets/imgthree/H5NEW/按钮H5.png"
          alt=""
        />
      </div>
      <img
        style="width: 100%"
        src="../assets/imgthree/H5NEW/1首页内容H5.jpg"
        alt=""
      />
       <img
        style="width: 100%"
        src="../assets/imgthree/H5NEW/homepro2.png"
        alt=""
      />
          <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/H5NEW/蓝色背景.jpg"
          alt=""
        />

        <img
          style="
            width: 73%;
            position: absolute;
            top: 65%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
           @click="gotobooknow()"
          src="../assets/imgthree/H5NEW/预定按钮.png"
          alt=""
        />
      </div>
        <div style="position: relative">
        <img
          style="width: 100%"
           src="../assets/imgthree/H5/底部H5.jpg"
          alt=""
        />
        <div>
          <span
            style="
                 position: absolute;
    top: 43.5%;
    left: 15%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
            @click="goto('PRODUCTS')"
            >PRODUCTS</span
          >
          <span
            style="
                position: absolute;
    top: 46%;
    left: 13.5%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
            @click="goto('GALLERY')"
            >GALLERY</span
          >
          <span
            style="
              position: absolute;
    top: 48.4%;
    left: 16.5%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
            @click="goto('ContactUs')"
            >CONTACT US</span
          >
          <span
            style="
               position: absolute;
    top: 50.6%;
    left: 15.2%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
              @click="goto('BOOKNOW')"
            >BOOK NOW</span
          >
          <!-- <span
            style="
                  position: absolute;
    top: 53.1%;
    left: 19.8%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
             @click="goto('privacyPolicy')"
            >PRIVACY POLICY</span
          > -->
            <span
            style="
              position: absolute;
              top: 71%;
              left: 30%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              font-weight: 700;
                color:white
            "
             @click="goto('AIR')"
            >AIR CONDITIONERS & REBATE</span
          >
        </div>
     
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  data() {
    return {
      imagesh5: [
        {
          idview: require("../assets/imgthree/H5NEW/轮播图1.jpg"),
        },
        { idview: require("../assets/imgthree/H5NEW/轮播图2.jpg") },
      ],
      imgHeight: "",
      // 产品图片，注意图片命名
      images: [
        {
          idview: require("../assets/imgthree/PCNEW/PC轮播图1.jpg"),
        },
        { idview: require("../assets/imgthree/PCNEW/PC轮播图2.jpg") },
        // { name: "home", idview: require("../assets/imgthree/PC/hometop3.png") },
      ],
    };
  },
  mounted() {
     document.documentElement.scrollTop = 0;
    this.imgLoad();
    window.onresize = () => {
      this.imgLoad();
    };

    // window.fbq("track", "ViewContent", {
    //   content_type: "index",
    //   content_ids: ["10001"],
    //   content_name: "index",
    //   content_category: "index",
    // });
  },
  destroyed() {
    window.onresize = null;
  },
  imgLoad() {
    this.$nextTick(() => {
      this.imgHeight = this.$refs.imgHeight["0"].height + "px";
    });
  },

  methods: {
    goto(name){
if(name=="PRODUCTS"||name=="AIR"){
  this.$router.push("/Products");
}else if(name=="GALLERY"){
  this.$router.push("/ProjectGallery")
}else if(name=="ContactUs"){
  this.$router.push("/ContactUscopy")
}else if(name=="BOOKNOW"){
  this.$router.push("/CustomerDetails");
  // window.open('https://smartuser.com.au/customerdetails/')
  // this.$router.push("/ProjectGallery")
}else if(name=="privacyPolicy"){
  this.$router.push("/privacyPolicy")
}
    },
    gotobooknow(){
this.$router.push("/CustomerDetails");
    },
    gotoAboutus() {
      this.$emit("fun", "/AboutUs");
    },
    imgLoad() {
      this.$nextTick(() => {
        this.imgHeight = this.$refs.imgHeight["0"].height + "px";
      });
    },
  },
};
</script>

<style  scoped lang="scss">
//  .el-carousel__item h3 {
//     color: #475669;
//     font-size: 18px;
//     opacity: 0.75;
//     line-height: 300px;
//     margin: 0;
//   }

//   .el-carousel__item:nth-child(2n) {
//     background-color: #99a9bf;
//   }

//   .el-carousel__item:nth-child(2n+1) {
//     background-color: #d3dce6;
//   }
::v-deep .el-carousel__arrow--left {
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 56px;
  width: 56px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  background-color: #c0c4cc;
  color: #fff;
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  text-align: center;
  font-size: 30px;
  // top: 70px;
  // left: -13px;
  // font-size: 15px;
  // color: #02E9FF;
  // display: none;
}
// ::v-deep.el-carousel__arrow {
//     border: none;
//     outline: 0;
//     padding: 0;
//     margin: 0;
//     height: 56px;
//     width: 56px;
//     cursor: pointer;
//     transition: .3s;
//     border-radius: 50%;
//     background-color: rgba(255,255,255,.5);
//     color: #FFF;
//     position: absolute;
//     top: 50%;
//     z-index: 10;
//     transform: translateY(-50%);
//     text-align: center;
//     font-size: 30px;
// }
//右箭头
::v-deep .el-carousel__arrow--right {
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 56px;
  width: 56px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  background-color: #c0c4cc;
  color: #fff;
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  text-align: center;
  font-size: 30px;
  // display: none;
  // top: 70px;
  // right: -13px;
  // font-size: 15px;
  // color: #02E9FF;
}
// .el-carousel__item h3 {
//     color: #475669;
//     font-size: 14px;
//     opacity: 0.75;
//     line-height: 150px;
//     margin: 0;
//   }

//   .el-carousel__item:nth-child(2n) {
//     background-color: #99a9bf;
//   }

//   .el-carousel__item:nth-child(2n + 1) {
//     background-color: #d3dce6;
//   }
// ::v-deep .el-carousel__indicator--horizontal {
//        position: relative;
//        bottom:0;
//         transform: translateX(-50%);
//         // left: auto;
//         // right: 10px;
//         // bottom: 10px;
//         // text-align: right;

// }
// ::v-deep.el-carousel__indicators { // 指示灯位置
//   left: unset;
//   transform: unset;
//   right: 2%;
// }
::v-deep .el-carousel__button {
  left: auto;
  right: 10px;
  bottom: 10px;
  text-align: right;
  // top: 4.5%;
  // right: 22%;
  display: block;
  opacity: 0.48;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: none;
  outline: 0;
  padding: 0;
  margin: 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}
// .el-carousel__item h3 {
//   color: #475669;
//   font-size: 14px;
//   opacity: 0.75;
//   line-height: 150px;
//   margin: 0;
// }

// .el-carousel__item:nth-child(2n) {
//    background-color: #99a9bf;
// }

// .el-carousel__item:nth-child(2n+1) {
//    background-color: #d3dce6;
// }
::v-deep .pchome.el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 15px;
}
// .el-carousel__item.is-animation{
//   transition:transform 1s ease-in-out;
// }
::v-deep .h5home .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-weight: 700;
  font-size: 17px;
}
.el-form-item {
  margin-bottom: 15px;
}
::v-deep.el-select .el-input__inner {
  border-radius: 20px;
}
.mt_20 {
  margin-top: 20px;
}
.mb_20 {
  margin-bottom: 20px;
}
::v-deep.el-input--suffix .el-input__inner {
  padding-right: 30px;
  border-radius: 20px;
}


</style>
