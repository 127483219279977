<template>
  <div v-title :data-title="$route.name">
    <!-- style=" border-style: solid; border-width: 5px; border-color: transparent;" -->
    <div ref="myElement" style="background: white">
      <!-- <section class="titlebar">
        <div class="row">
          <h1 id="page-title" class="title text-center">Contact Us</h1>
        </div>
      </section> -->

      <div v-if="offsetwidth > 850">
        <div style="display: flex; justify-content: center">
          <div
            style="
              width: 100%;
              text-align: center;
              padding: 10px;
              margin-top: 65px;
            "
          >
            <span style="font-weight: 700; font-size: 35px">Contact Us</span>
          </div>
        </div>
        <main
          class="l-main"
          style="margin-top: 100px; display: flex; justify-content: center"
        >
          <div class="main row">
            <article>
              <div class="body field clearfix">
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid"
                  style="
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    width: 1243px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-8"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="30"
                        data-heightdesktop="60"
                        style="height: 60px"
                      ></div>

                      <div class="wd-heading text-center">
                        <h2
                          style="
                            margin: 0;
                            font-size: 25px;
                            color: #686866;
                            text-transform: Uppercase;
                            letter-spacing: 1px;
                          "
                        >
                          Contact Details
                        </h2>

                        <div style="margin-top: 20px"></div>
                      </div>

                      <div class="wpb_text_column wpb_content_element">
                        <div class="wpb_wrapper">
                          <p style="text-align: center">
                            We have stores in multiple major shopping centres
                            including Westfield Shopping centres and Vicinity
                            Centres. You can easily find our store locations by
                            <span
                              style="color: rgb(38, 95, 180)"
                              @click="scrollpostion('ditu')"
                              >click the link here</span
                            >.
                          </p>
                        </div>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="10"
                        data-heighttablet="20"
                        data-heightdesktop="60"
                        style="height: 60px"
                      ></div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid"
                  style="
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    width: 1243px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="boxes small layout-3 clearfix">
                        <div class="box-container clearfix">
                          <div class="box-icon">
                            <i class="fa fa-phone" style="background: none"></i>
                          </div>

                          <h3 class="box-title-3">Phone</h3>

                          <hr />

                          <p class="box-body">(03)8824 0619</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="boxes small layout-3 clearfix">
                        <div class="box-container clearfix">
                          <div class="box-icon">
                            <i
                              class="fa fa-location-arrow"
                              style="background: none"
                            ></i>
                          </div>

                          <h3 class="box-title-3">Head Office Address</h3>

                          <hr />

                          <p class="box-body">
                            u2/11 Howleys Rd，<br />
                            Notting Hill VIC 3168
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="boxes small layout-3 clearfix last-box">
                        <div class="box-container clearfix">
                          <div class="box-icon">
                            <i
                              class="fa fa-envelope"
                              style="background: none"
                            ></i>
                          </div>

                          <h3 class="box-title-3">Email</h3>

                          <hr />

                          <p class="box-body">Info@siliconelectronics.com.au</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="boxes small layout-3 clearfix last-box">
                        <div class="box-container clearfix">
                          <div class="box-icon">
                            <i class="fa fa-link" style="background: none"></i>
                          </div>

                          <h3 class="box-title-3">Social Media</h3>

                          <hr />

                          <p class="box-body">
                            <a
                              href="https://www.facebook.com/SmartUser.VIC.FB"
                              onclick="javascript:window.open('https://www.facebook.com/SmartUser.VIC.FB'); return false;"
                              >FaceBook</a
                            ><br />
                            <a
                              href="https://www.instagram.com/smartuser_aus/"
                              onclick="javascript:window.open('https://www.instagram.com/smartuser_aus/'); return false;"
                              >Instagram</a
                            ><br />
                            <a
                              href="https://www.youtube.com/channel/UCzGqH7hM1Z8cyHrsjAWb0ng"
                              onclick="javascript:window.open('https://www.youtube.com/channel/UCzGqH7hM1Z8cyHrsjAWb0ng'); return false;"
                              >YouTube</a
                            ><br />
                            <a
                              href="https://mp.weixin.qq.com/cgi-bin/home?t=home/index&amp;token=410767345&amp;lang=en_US"
                              onclick="javascript:window.open('https://mp.weixin.qq.com/cgi-bin/home?t=home/index&amp;token=410767345&amp;lang=en_US'); return false;"
                              >WeChat</a
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  id="pagesditu"
                  class="vc_row wpb_row vc_row-fluid vc_row-o-equal-height vc_row-o-content-top vc_row-flex"
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-6 vc_custom_1630295953993"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="wpb_gmaps_widget wpb_content_element">
                        <div class="wpb_wrapper">
                          <div class="wpb_map_wraper">
                            <!-- <img
          style="width: 100%;"
          src="../assets/imgthree/PC/googleimg.png"
          alt=""
        /> -->
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3148.2247143741815!2d145.13531447663155!3d-37.90180993907627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad615d3ef9ae38f%3A0x551902f8ffd0d44a!2sSilicon%20Electronics!5e0!3m2!1sen!2sau!4v1738725610939!5m2!1sen!2sau"
                              width="600"
                              height="450"
                              style="border: 0"
                              allowfullscreen=""
                              loading="lazy"
                              referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>

                            <!-- <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3148.2279851315852!2d145.1351360153212!3d-37.90173347973657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad66ab2d4a5f8af%3A0xba3b40cdaf7cd561!2s11%20Howleys%20Rd%2C%20Notting%20Hill%20VIC%203168!5e0!3m2!1sen!2sau!4v1614663394452!5m2!1sen!2sau"
                              width="670"
                              height="300"
                              style="border: 0px; pointer-events: none"
                              allowfullscreen=""
                              loading="lazy"
                            ></iframe> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-6 vc_custom_1630295960617"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="wd-heading text-center">
                        <h2 style="margin: 0; font-size: 35px">Get in touch</h2>

                        <hr style="border-color: #49d0e5; margin: 10px 0" />
                        <p style="">
                          If you have any questions regarding our products and
                          services, please contact us by filling up the form and
                          we will get back to you as soon as we can. You can
                          also get in touch with us by calling (03)8824 0619.
                        </p>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="10"
                        data-heighttablet="10"
                        data-heightdesktop="10"
                        style="height: 10px"
                      ></div>

                      <div
                        role="form"
                        class="wpcf7"
                        id="wpcf7-f3565-p20-o1"
                        lang="en-US"
                        dir="ltr"
                      >
                        <div class="screen-reader-response">
                          <p
                            role="status"
                            aria-live="polite"
                            aria-atomic="true"
                          ></p>
                          <ul></ul>
                        </div>
                        <div
                          class="wpcf7-form init"
                          novalidate="novalidate"
                          data-status="init"
                        >
                          <div style="display: none">
                            <input type="hidden" name="_wpcf7" value="3565" />
                            <input
                              type="hidden"
                              name="_wpcf7_version"
                              value="5.4.2"
                            />
                            <input
                              type="hidden"
                              name="_wpcf7_locale"
                              value="en_US"
                            />
                            <input
                              type="hidden"
                              name="_wpcf7_unit_tag"
                              value="wpcf7-f3565-p20-o1"
                            />
                            <input
                              type="hidden"
                              name="_wpcf7_container_post"
                              value="20"
                            />
                            <input
                              type="hidden"
                              name="_wpcf7_posted_data_hash"
                              value=""
                            />
                            <input
                              type="hidden"
                              name="_wpcf7_recaptcha_response"
                              value="03AFcWeA70h9oghURiB62rmXlIEbnvkXT7aYJeT-ga3IcwAsA1rtcwsSgcufoP-hh8kGuNzoXY_VZ1Fp_VHkhCVj6-4e48yA4OlJhsEY44HUUyldakWuy2M5hMkZACEV0TLc-tqY_kXDADmusXJb4Qpk9XQe2Rw0M9E5n7oo_BmovH28X8QIVmuChq4mpZJqGQXUGjM0pvDydeiuZmkBMX1tqK_1sB0DYwWXf49CFsS0AmZxD78jZ1oq_5ttHpjlHqCL4E4aKub3Txy5VjkT28HbkmhzFm7GzEs64fu-gFKJkHhYL_srpiGQC5N2RFBL8zdLo_HMEoui9IQF40oeKJ5hqpoEgeqlc1aTDiFsNmP4Q83KgiYnZPgMVDTJG8WdSI5yG1Wfn0a7cn9XVGs4Mewwsz3xXmVVKh5WZwOp7N0CHtuJvm_BRYCTpa8DB44d-yibJTBHFlt5iawgqd6avFrrwx2uFQ6AePacmGjLNfY1nCJLDaykmas1SrkdtnkgShgllIILedf1W1Gh89zCwpl4Wd12WQP1mKcCeKWb9hqQLPKLGxmE2Hr56gqCK4B-HPn-sW3gZRl4aW8xGmCOjiIr37gksC9vfGUWWKaq2sas_Qb5_okJYdZkdVHaXv1p5OWsoXo7n9ba7wk5uZceCtG8Y8Piu2MRn40DZP56baM_Z80VhZIexcKvk"
                            />
                          </div>
                          <div class="row">
                            <div
                              style="
                                display: flex;
                                justify-content: space-between;
                              "
                            >
                              <div class="large-6" style="text-align: left">
                                Name <span class="red">*</span><br />
                                <span class="wpcf7-form-control-wrap your-name"
                                  ><input
                                    type="text"
                                    id="your-name"
                                    name="your-name"
                                    value=""
                                    size="40"
                                    class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                    aria-required="true"
                                    aria-invalid="false"
                                /></span>
                              </div>
                              <div class="large-6" style="text-align: left">
                                Email <span class="red">*</span><br />
                                <span class="wpcf7-form-control-wrap your-email"
                                  ><input
                                    type="email"
                                    id="your-email"
                                    name="your-email"
                                    value=""
                                    size="40"
                                    class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                    aria-required="true"
                                    aria-invalid="false"
                                /></span>
                              </div>
                            </div>
                            <div
                              class="large-12 columns"
                              style="text-align: left"
                            >
                              Phone<br />
                              <span class="wpcf7-form-control-wrap your-phone"
                                ><input
                                  type="tel"
                                  id="your-phone"
                                  name="your-phone"
                                  value=""
                                  size="40"
                                  class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel"
                                  aria-invalid="false"
                              /></span>
                            </div>
                            <div
                              class="large-12 columns"
                              style="text-align: left"
                            >
                              Subject<br />
                              <span class="wpcf7-form-control-wrap your-subject"
                                ><input
                                  id="your-subject"
                                  type="text"
                                  name="your-subject"
                                  value=""
                                  size="40"
                                  class="wpcf7-form-control wpcf7-text"
                                  aria-invalid="false"
                              /></span>
                            </div>
                            <div
                              class="large-12 columns"
                              style="text-align: left"
                            >
                              Your Message<br />
                              <span
                                class="wpcf7-form-control-wrap textarea-470"
                              >
                                <textarea
                                  id="textarea-470"
                                  name="textarea-470"
                                  cols="40"
                                  rows="5"
                                  class="wpcf7-form-control wpcf7-textarea"
                                  aria-invalid="false"
                                ></textarea>
                              </span>
                            </div>
                          </div>
                          <p style="text-align: left">
                            <br /><br />

                            <button
                              type="submit"
                              class="contact-form-btn"
                              @click="Submitform()"
                            >
                              Submit
                            </button>
                          </p>
                          <!-- <input
                          type="hidden"
                          class="wpcf7-pum"
                          value='{"closepopup":false,"closedelay":0,"openpopup":false,"openpopup_id":0}'
                        />
                        <div
                          class="wpcf7-response-output"
                          aria-hidden="true"
                        ></div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1629081191068 vc_row-has-fill"
                  style="
                    background: white !important;
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    width: 1243px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-8"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                </div>

                <div class="vc_row-full-width vc_clearfix"></div>

                <!-- <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1627275220329 vc_row-has-fill"
                  style="
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    width: 1243px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-9"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <h2
                        style="
                          font-size: 45px;
                          color: #ffffff;
                          text-align: left;
                        "
                        class="vc_custom_heading"
                      >
                        <a
                          href="https://www.smart-user.co/new_app/24"
                          onclick="javascript:window.open('https://www.smart-user.co/new_app/24'); return false;"
                          >Start Saving Today!</a
                        >
                      </h2>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="vc_btn3-container order-btn vc_btn3-left">
                        <a
                          style="background-color: #ffffff; color: #000000"
                          class="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-round vc_btn3-style-custom"
                          href="https://smartuser.com.au/customerdetails/"
                          title="Contact"
                          target="_blank"
                          >Book Now For Free Consultation</a
                        >
                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="vc_row-full-width vc_clearfix"></div>
                <div class="vc_row wpb_row vc_row-fluid">
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                </div>
              </div>
            </article>
          </div>

          <!-- <div style="position: relative">
            <img style="width: 100%" src="../assets/sp/start.jpg" alt="" />

            <img
              style="
                width: 18%;
                position: absolute;
                top: 50%;
                left: 70%;
                cursor: pointer;
                transform: translate(-50%, -50%);
              "
              @click="goto()"
              src="../assets/sp/startbutton.png"
              alt=""
            />
          </div> -->
        </main>
      </div>
      <div
        v-else
        style="
          width: 100%;
          border: 1px solid #f8f9fa;
          overflow: hidden;
          position: relative;
          margin-top: 65px;
        "
      >
        <div style="display: flex; justify-content: center">
          <div style="width: 100%; text-align: center; padding: 10px">
            <span style="font-weight: 700; font-size: 35px">Contact Us</span>
          </div>
        </div>
        <main class="l-main">
          <div class="main row">
            <article>
              <div class="body field clearfix">
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid"
                  style="
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    width: 400px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-8"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="30"
                        data-heightdesktop="60"
                        style="height: 20px"
                      ></div>

                      <div class="wd-heading text-center">
                        <h2
                          style="
                            margin: 0;
                            font-size: 25px;
                            color: #686866;
                            text-transform: Uppercase;
                            letter-spacing: 1px;
                          "
                        >
                          Contact Details
                        </h2>

                        <div style="margin-top: 20px"></div>
                      </div>

                      <div class="wpb_text_column wpb_content_element">
                        <div class="wpb_wrapper">
                          <p style="text-align: center">
                            We have stores in multiple major shopping centres
                            including Westfield Shopping centres and Vicinity
                            Centres. You can easily find our store locations by
                            <span
                              style="color: rgb(38, 95, 180)"
                              @click="scrollpostioncopy('ditu')"
                              >click the link here</span
                            >.
                          </p>
                        </div>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="10"
                        data-heighttablet="20"
                        data-heightdesktop="60"
                        style="height: 10px"
                      ></div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid"
                  style="
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    width: 400px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="boxes small layout-3 clearfix">
                        <div class="box-container clearfix">
                          <div class="box-icon">
                            <i class="fa fa-phone" style="background: none"></i>
                          </div>

                          <h3 class="box-title-3">Phone</h3>

                          <hr />

                          <p class="box-body">(03)8824 0619</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="boxes small layout-3 clearfix">
                        <div class="box-container clearfix">
                          <div class="box-icon">
                            <i
                              class="fa fa-location-arrow"
                              style="background: none"
                            ></i>
                          </div>

                          <h3 class="box-title-3">Head Office Address</h3>

                          <hr />

                          <p class="box-body">
                            u2/11 Howleys Rd，<br />
                            Notting Hill VIC 3168
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="boxes small layout-3 clearfix last-box">
                        <div class="box-container clearfix">
                          <div class="box-icon">
                            <i
                              class="fa fa-envelope"
                              style="background: none"
                            ></i>
                          </div>

                          <h3 class="box-title-3">Email</h3>

                          <hr />

                          <p class="box-body">Info@siliconelectronics.com.au</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="boxes small layout-3 clearfix last-box">
                        <div class="box-container clearfix">
                          <div class="box-icon">
                            <i class="fa fa-link" style="background: none"></i>
                          </div>

                          <h3 class="box-title-3">Social Media</h3>

                          <hr />

                          <p class="box-body"></p>
                        </div>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="20"
                        data-heighttablet="130px"
                        data-heightdesktop="20"
                        style="height: 20px"
                      ></div>

                      <div class="vc_wp_text wpb_content_element scmb">
                        <div class="widget widget_text">
                          <div class="textwidget">
                            <p>
                              <a
                                href="https://www.facebook.com/SmartUser.VIC.FB"
                                onclick="javascript:window.open('https://www.facebook.com/SmartUser.VIC.FB'); return false;"
                                >FaceBook</a
                              ><br />
                              <a
                                href="https://www.instagram.com/smartuser_aus/"
                                onclick="javascript:window.open('https://www.instagram.com/smartuser_aus/'); return false;"
                                >Instagram</a
                              ><br />
                              <a
                                href="https://www.youtube.com/channel/UCzGqH7hM1Z8cyHrsjAWb0ng"
                                onclick="javascript:window.open('https://www.youtube.com/channel/UCzGqH7hM1Z8cyHrsjAWb0ng'); return false;"
                                >YouTube</a
                              ><br />
                              <a
                                href="https://mp.weixin.qq.com/cgi-bin/home?t=home/index&amp;token=410767345&amp;lang=en_US"
                                onclick="javascript:window.open('https://mp.weixin.qq.com/cgi-bin/home?t=home/index&amp;token=410767345&amp;lang=en_US'); return false;"
                                >WeChat</a
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>

                <div
                  id="pagesditu"
                  class="vc_row wpb_row vc_row-fluid vc_row-o-equal-height vc_row-o-content-top vc_row-flex"
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-6 vc_custom_1630295953993"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="wpb_gmaps_widget wpb_content_element">
                        <div>
                          <div>
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3148.2247143741815!2d145.13531447663155!3d-37.90180993907627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad615d3ef9ae38f%3A0x551902f8ffd0d44a!2sSilicon%20Electronics!5e0!3m2!1sen!2sau!4v1738725610939!5m2!1sen!2sau"
                              width="600"
                              height="450"
                              style="border: 0"
                              allowfullscreen=""
                              loading="lazy"
                              referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                            <!-- <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3148.2279851315852!2d145.1351360153212!3d-37.90173347973657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad66ab2d4a5f8af%3A0xba3b40cdaf7cd561!2s11%20Howleys%20Rd%2C%20Notting%20Hill%20VIC%203168!5e0!3m2!1sen!2sau!4v1614663394452!5m2!1sen!2sau"
                              width="670"
                              height="300"
                              style="border: 0px; pointer-events: none"
                              allowfullscreen=""
                              loading="lazy"
                            ></iframe> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div
                    class="wpb_column vc_column_container vc_col-sm-6 vc_custom_1630295960617"
                    data-equalizer-watch=""
                  >
                    <div style="text-align: center">
                      <img
                        style="width: 100%"
                        src="../assets/imgthree/PC/googleimg.png"
                        alt=""
                      />
                    </div>
                  </div> -->
                  <div
                    class="wpb_column vc_column_container vc_col-sm-6 vc_custom_1630295960617"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="wd-heading text-center">
                        <h2 style="margin: 0; font-size: 35px">Get in touch</h2>

                        <hr style="border-color: #49d0e5; margin: 10px 0" />
                        <p style="">
                          If you have any questions regarding our products and
                          services, please contact us by filling up the form and
                          we will get back to you as soon as we can. You can
                          also get in touch with us by calling (03)8824 0619.
                        </p>
                      </div>

                      <div
                        class="revaldak_empty_space"
                        data-heightmobile="10"
                        data-heighttablet="10"
                        data-heightdesktop="10"
                        style="height: 10px"
                      ></div>

                      <div
                        role="form"
                        class="wpcf7"
                        id="wpcf7-f3565-p20-o1"
                        lang="en-US"
                        dir="ltr"
                      >
                        <div class="screen-reader-response">
                          <p
                            role="status"
                            aria-live="polite"
                            aria-atomic="true"
                          ></p>
                          <ul></ul>
                        </div>
                        <div
                          class="wpcf7-form init"
                          novalidate="novalidate"
                          data-status="init"
                        >
                          <div style="display: none">
                            <input type="hidden" name="_wpcf7" value="3565" />
                            <input
                              type="hidden"
                              name="_wpcf7_version"
                              value="5.4.2"
                            />
                            <input
                              type="hidden"
                              name="_wpcf7_locale"
                              value="en_US"
                            />
                            <input
                              type="hidden"
                              name="_wpcf7_unit_tag"
                              value="wpcf7-f3565-p20-o1"
                            />
                            <input
                              type="hidden"
                              name="_wpcf7_container_post"
                              value="20"
                            />
                            <input
                              type="hidden"
                              name="_wpcf7_posted_data_hash"
                              value=""
                            />
                            <input
                              type="hidden"
                              name="_wpcf7_recaptcha_response"
                              value="03AFcWeA70h9oghURiB62rmXlIEbnvkXT7aYJeT-ga3IcwAsA1rtcwsSgcufoP-hh8kGuNzoXY_VZ1Fp_VHkhCVj6-4e48yA4OlJhsEY44HUUyldakWuy2M5hMkZACEV0TLc-tqY_kXDADmusXJb4Qpk9XQe2Rw0M9E5n7oo_BmovH28X8QIVmuChq4mpZJqGQXUGjM0pvDydeiuZmkBMX1tqK_1sB0DYwWXf49CFsS0AmZxD78jZ1oq_5ttHpjlHqCL4E4aKub3Txy5VjkT28HbkmhzFm7GzEs64fu-gFKJkHhYL_srpiGQC5N2RFBL8zdLo_HMEoui9IQF40oeKJ5hqpoEgeqlc1aTDiFsNmP4Q83KgiYnZPgMVDTJG8WdSI5yG1Wfn0a7cn9XVGs4Mewwsz3xXmVVKh5WZwOp7N0CHtuJvm_BRYCTpa8DB44d-yibJTBHFlt5iawgqd6avFrrwx2uFQ6AePacmGjLNfY1nCJLDaykmas1SrkdtnkgShgllIILedf1W1Gh89zCwpl4Wd12WQP1mKcCeKWb9hqQLPKLGxmE2Hr56gqCK4B-HPn-sW3gZRl4aW8xGmCOjiIr37gksC9vfGUWWKaq2sas_Qb5_okJYdZkdVHaXv1p5OWsoXo7n9ba7wk5uZceCtG8Y8Piu2MRn40DZP56baM_Z80VhZIexcKvk"
                            />
                          </div>
                          <div class="row">
                            <div
                              class="large-6 columns"
                              style="text-align: left"
                            >
                              Name <span class="red">*</span><br />
                              <span class="wpcf7-form-control-wrap your-name"
                                ><input
                                  type="text"
                                  id="your-name"
                                  name="your-name"
                                  value=""
                                  size="40"
                                  class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                  aria-required="true"
                                  aria-invalid="false"
                              /></span>
                            </div>
                            <div class="columns" style="text-align: left">
                              Email <span class="red">*</span><br />
                              <span class="wpcf7-form-control-wrap your-email"
                                ><input
                                  type="email"
                                  id="your-email"
                                  name="your-email"
                                  value=""
                                  size="40"
                                  class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                  aria-required="true"
                                  aria-invalid="false"
                              /></span>
                            </div>
                            <div
                              class="large-12 columns"
                              style="text-align: left"
                            >
                              Phone<br />
                              <span class="wpcf7-form-control-wrap your-phone"
                                ><input
                                  type="tel"
                                  id="your-phone"
                                  name="your-phone"
                                  value=""
                                  size="40"
                                  class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel"
                                  aria-invalid="false"
                              /></span>
                            </div>
                            <div
                              class="large-12 columns"
                              style="text-align: left"
                            >
                              Subject<br />
                              <span class="wpcf7-form-control-wrap your-subject"
                                ><input
                                  id="your-subject"
                                  type="text"
                                  name="your-subject"
                                  value=""
                                  size="40"
                                  class="wpcf7-form-control wpcf7-text"
                                  aria-invalid="false"
                              /></span>
                            </div>
                            <div
                              class="large-12 columns"
                              style="text-align: left"
                            >
                              Your Message<br />
                              <span
                                class="wpcf7-form-control-wrap textarea-470"
                              >
                                <textarea
                                  id="textarea-470"
                                  name="textarea-470"
                                  cols="40"
                                  rows="5"
                                  class="wpcf7-form-control wpcf7-textarea"
                                  aria-invalid="false"
                                ></textarea>
                              </span>
                            </div>
                          </div>
                          <p style="text-align: left">
                            <br /><br />

                            <button
                              type="submit"
                              class="contact-form-btn"
                              @click="Submitform()"
                            >
                              Submit
                            </button>
                          </p>
                          <!-- <input
                          type="hidden"
                          class="wpcf7-pum"
                          value='{"closepopup":false,"closedelay":0,"openpopup":false,"openpopup_id":0}'
                        />
                        <div
                          class="wpcf7-response-output"
                          aria-hidden="true"
                        ></div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1629081191068 vc_row-has-fill"
                  style="
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    width: 400px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-8"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-2"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="true"
                  class="vc_row wpb_row vc_row-fluid vc_custom_1627275220329 vc_row-has-fill"
                  style="
                    position: relative;
                    left: 0px;
                    box-sizing: border-box;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
                >
                  <div
                    class="wpb_column vc_column_container vc_col-sm-9"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <h2
                        style="
                          font-size: 45px;
                          color: #ffffff;
                          text-align: left;
                        "
                        class="vc_custom_heading"
                      >
                        <a
                          href="https://www.smart-user.co/new_app/24"
                          onclick="javascript:window.open('https://www.smart-user.co/new_app/24'); return false;"
                          >Start Saving Today!</a
                        >
                      </h2>
                    </div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper">
                      <div class="vc_btn3-container order-btn vc_btn3-left">
                        <a
                          style="background-color: #ffffff; color: #000000"
                          class="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-round vc_btn3-style-custom"
                          href="https://smartuser.com.au/customerdetails"
                          title="Contact"
                          target="_blank"
                          >Book Now For Free Consultation</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vc_row-full-width vc_clearfix"></div>
                <div class="vc_row wpb_row vc_row-fluid">
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                  <div
                    class="wpb_column vc_column_container vc_col-sm-3"
                    data-equalizer-watch=""
                  >
                    <div class="wpb_wrapper"></div>
                  </div>
                </div> -->
              </div>
            </article>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post } from "../assets/api/api";
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  mounted() {},

  methods: {
    scrollpostion(key) {
      var PageId = document.querySelector("#pages" + key);
      window.scrollTo({
        top: PageId.offsetTop - 200,
        behavior: "smooth",
      });
    },
    scrollpostioncopy(key) {
      var PageId = document.querySelector("#pages" + key);
      window.scrollTo({
        top: PageId.offsetTop,
        behavior: "smooth",
      });
    },
    goto() {
      window.open("/Customerdetails", "_blank");
    },
    Submitform() {
      let name = document.getElementById("your-name");
      let email = document.getElementById("your-email");
      let phone = document.getElementById("your-phone");
      let subject = document.getElementById("your-subject");
      let message = document.getElementById("textarea-470");
      console.log(name.value);
      if (name.value == "") {
        return this.$message.error("Please enter Name.");
      }
      if (email.value == "") {
        return this.$message.error("Please enter Email.");
      }
      let data = {
        Name: name.value,
        Email: email.value,
        Phone: phone.value,
        Subject: subject.value,
        ClientMessage: message.value,
      };
      let url = "/api/api/QuanX/SmartUserOfficWebSubGetInTouch";
      post(url, data).then((res) => {
        if (res.data.code == 201) {
          this.$message.success("Succeed");
          name.value = "";
          email.value = "";
          phone.value = "";
          subject.value = "";
          message.value = "";
        }
      });
    },
  },
};
</script>

<style scoped>
img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}
button[type="submit"] {
  position: relative;
  box-shadow: none;
  border-radius: 30px;
  padding: 13px 20px 13px 20px;
  transition: 0.5s ease;
}
.contact-form-btn:before {
  content: "\2192"; /* 右箭头 */
  font-family: FontAwesome;
  position: absolute;
  right: 20px;
  color: #58c747;
  font-size: 20px;
  font-weight: 700;
  z-index: 2;
}
.contact-form-btn:after {
  content: "";
  padding-left: 0;
  position: absolute;
  top: 0;
  right: -5px;
  height: 100%;
  width: 65px;
  -webkit-transform: skew(-30deg);
  transform: skew(-30deg);
  background-color: #fff;
  z-index: 1;
}
button:after,
.button:after {
  font-family: FontAwesome;
  /* content: "\f178"; */
  padding-left: 10px;
}
.contact-form-btn {
  position: relative;
  box-shadow: none;
  border-radius: 30px;
  padding: 13px 20px 13px 20px;
  transition: 0.5s ease;
  text-align: left;
  overflow: hidden;
  width: 190px;
  background-color: #58c747;
  border: 0;
  color: #fff;
  text-transform: none;
  font-size: 15px;
  outline: none;
}
.vc_custom_1627275220329 {
  margin-bottom: -40px !important;
  padding-top: 50px !important;
  padding-bottom: 40px !important;
  background-color: #84b903 !important;
}
img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}
.titlebar {
  width: 100%;
  height: 0;
  padding-bottom: 20.45%;
  /* background: url(https://smartuser.com.au/wp-content/uploads/2023/01/banner-2.jpg); */
  background-size: 100%;
  background-repeat: no-repeat;
}
.vc_custom_1630295953993 {
  padding-top: 30px !important;
  padding-right: 60px !important;
  padding-bottom: 30px !important;
  padding-left: 30px !important;
}
.vc_custom_1630295960617 {
  padding-top: 30px !important;
  padding-right: 30px !important;
  /* padding-bottom: 10px !important; */
  padding-left: 60px !important;
}
.special-link {
  text-decoration: underline;
}

.mt_20 {
  margin-top: 20px;
}
.mt_10 {
  margin-top: 10px;
}
.text_bold {
  font-weight: 700;
}
.pcss {
  font-family: Poppins;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
}
.titlecss {
  font-family: Poppins;
  font-style: normal;
}
.vc_custom_1629081191068 {
  padding-top: 100px !important;
  background-color: #fbfbfb !important;
}
</style>
