<template>
  <div v-title :data-title="$route.name">
    <!-- style=" border-style: solid; border-width: 5px; border-color: transparent;" -->
    <div ref="myElement" style="background:white;margin-top:65px">
      <div v-if="offsetwidth > 850">
        <div style="display: flex; justify-content: center">
          <div
            style="
              width: 76%;
              border: 1px solid #ccc;
              border-radius: 8px;
              padding: 10px;
            "
          >
            <div
              style="font-weight: 700; text-align: center; font-size: 32px"
              class="mt_20"
            >
              Terms and Conditions for Online Marketing Campaigns
            </div>
            <div style="text-align:left">
            <div style="font-weight: 700; font-size: 17px" class="mt_20">
              1.Introduction
            </div>

            <div class="mt_10" style="margin-left: 20px">
              1.1.These terms and conditions along with our Website Terms and
              Conditions of Use and Privacy Policy along with any further terms
              and conditions deemed required by us (collectively “<span
                style="font-weight: 700; font-size: 17px"
                >Terms and Conditions</span
              >”) form the terms and conditions governing your participation in
              any online marketing promotion (“<span
                style="font-weight: 700; font-size: 17px"
                >Promotion</span
              >”) conducted by MORRIS GROUP AUSTRALIA Pty Ltd trading as Smart
              User (“us/we”). By participating in a Promotion, you acknowledge
              and agree that you have read and agree to be bound by the Terms
              and Conditions.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              1.2.You may also accept the Terms and Conditions by clicking to
              accept or agree to the Terms and Conditions where this option is
              made available to you by us in the user interface.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              2.Participation
            </div>
            <div class="mt_10" style="margin-left: 20px">
              2.1.Participants agree to be bound by and comply with the Terms
              and Conditions at all times.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              2.2.Our decision regarding eligibility, or Promotion participation
              criteria is final and binding.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              3.Promotion
            </div>
            <div class="mt_10" style="margin-left: 20px">
              3.1.We reserve the right in our sole discretion to the fullest
              extent permitted by law to modify, change, extend, withdraw,
              remove or cancel a Promotion at any time subject to any directions
              from any relevant authority, if a Promotion is interfered with in
              any way or is not capable of being conducted as anticipated due to
              any reason beyond our reasonable control subject to relevant State
              or Territory regulations at all times..
            </div>
            <div class="mt_10" style="margin-left: 20px">
              3.2.Each Promotion will have a specified Promotion Period at which
              point the Promotion will expire and no longer be valid. We reserve
              the right to extend or shorten the Promotion Period at our sole
              discretion, subject to relevant State or Territory regulations.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              4.Eligibility
            </div>
            <div class="mt_10" style="margin-left: 20px">
              4.1.Promotional offers may be subject to eligibility criteria,
              including but not limited to where a Promotion is linked to the
              Victorian Energy Upgrades (VEU) program.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              4.2.Eligibility criteria may differ or vary between Promotions and
              a participant must read all Terms and Conditions and eligibility
              criteria carefully before proceeding.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              4.3.Only valid and eligible participants will be accepted to
              participate in a Promotion. We reserve the right to verify
              participant eligibility and disqualify/not honour any participant
              who fails to satisfy the required eligibility criteria at any
              time, at our sole discretion subject to relevant State or
              Territory regulations at all times.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              4.4.The eligibility criteria for a Promotion will be specified as
              required in the respective Promotion materials. Factors such as
              age, location, previous participation, and other relevant
              considerations may determine eligibility.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              4.5.The participant warrants that any information provided to us
              in the course of completing the eligibility process, online or
              otherwise, will always be accurate, correct and up to date.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              4.6.Entry and continued participation in a Promotion is strictly
              subject to and dependent on a participant’s compliance at all
              times with all relevant eligibility criteria and the Terms and
              Conditions.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              5.VEU Program
            </div>
            <div class="mt_10" style="margin-left: 20px">
              5.1.We are an accredited provider under the Victorian Energy
              Upgrades (VEU) program, being a voluntary Victorian government
              initiative that provides incentives for Victorians to make
              energy-efficient improvements to their homes and businesses.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              5.2.Many of our Promotions are linked to the VEU program in which
              specific eligibility criteria of participants apply.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              5.3.Should a Promotion be linked in anyway to the VEU program, a
              participant must satisfy all VEU program eligibility requirements
              prior to being entitled to participate in the Promotion.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              5.4.Access to the VEU program and eligibility requirements can be
              found via the below links:
            </div>
            <div class="mt_10" style="margin-left: 50px">
              5.4.1.
              <a
              class="special-link"
                href="https://www.energy.vic.gov.au/households/victorian-energy-upgrades-for-households/about-the-veu-program"
                target="top"
                >About the VEU Program</a
              >
            </div>
            <div class="mt_10" style="margin-left: 50px">
              5.4.2.
              <a
              class="special-link"
                href="https://www.energy.vic.gov.au/__data/assets/pdf_file/0028/585154/Victorian-Energy-Efficiency-Target-scheme-consumer-factsheet.pdf"
                target="top"
                >Victorian Energy Efficiency Target (VEET) scheme consumer
                factsheet</a
              >
            </div>
            <div class="mt_10" style="margin-left: 50px">
              5.4.3.
              <a
              class="special-link"
                href="https://www.energy.vic.gov.au/households/victorian-energy-upgrades-for-households"
                target="top"
                >Victorian Energy Upgrades for households</a
              >
            </div>
            <div class="mt_10" style="margin-left: 50px">
              5.4.4.<a
              class="special-link"
                href="https://www.esc.vic.gov.au/sites/default/files/documents/Code%20of%20Conduct%20Guideline%20-%20FINAL_0.pdf"
                target="top"
                >VEET Code of Conduct Guideline</a
              >
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              6.Promotions
            </div>
            <div class="mt_10" style="margin-left: 20px">
              6.1.We endeavour to display all information correctly, however we
              reserve the right to correct any errors in respect of a Promotion
              at any time.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              6.2.Unless otherwise specified a Promotion offer cannot be used in
              conjunction with any other offer.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              6.3.We reserve the right to end a Promotion at any time subject to
              relevant State or Territory regulations.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              6.4.To the furthest extent permitted by law, we are not liable for
              any financial loss arising out of the refusal, cancellation or
              withdrawal of any Promotion or any failure or inability of a
              participant to take advantage of a Promotion for any reason.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              7.Free Gifts
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.1.Nature and Specifications: any free gift(s) offered as part of
              a Promotion will be described in the Promotion materials as
              required. The nature, quantity, specifications, and value of the
              free gift(s) may vary between Promotions and are subject to
              availability and while stocks last (subject to extension) with
              each Promotion offer subject to change.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.2.Product exclusion: From time-to-time certain products may be
              excluded from a Promotion and any such exclusion will be notified
              to participants.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.3.Distribution: any free gift(s) must be taken as offered and
              may not be varied. We accept no liability for any variation in the
              value of the free gift(s) at time of receipt. Free gifts will be
              distributed to eligible participants as per our Terms and
              Conditions. We reserve the right to substitute the free gift(s) or
              any element of it, with an alternative of equal or greater value
              and specification if unavailable.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.4.Delivery: We will make reasonable efforts to deliver free
              gifts to eligible participants within a reasonable timeframe.
              Delivery times may vary depending on factors such as shipping
              location and availability of the free gift(s).
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.5.Non-Transferable: Free gifts are non-transferable,
              non-exchangeable, and non-refundable. Participants may not request
              cash or any alternative compensation in lieu of the free gift(s).
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.6.Lost or Damaged Gifts: Participants are responsible for
              providing accurate delivery information to us prior to
              distributing the free gift(s) We provide no guarantee of the
              successful delivery of the free gift(s) and we are not liable in
              any way for any lost, stolen, damaged, or undelivered free gift(s)
              once they have been dispatched for delivery and the participant
              indemnifies and holds us forever harmless against any loss or
              damage incurred 7.6.by the participant should the free gift(s)
              fail to be received.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.7.Taxes and Fees: We accept no responsibility for any tax
              implications relating to the acceptance of any free gift(s).
              Participants are responsible for any applicable taxes, duties, or
              other fees associated with receiving the free gift(s) and should
              seek independent financial advice accordingly. The Participant
              indemnifies us against any such taxes, duties, or other fees in
              their entirety.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.8.Expiry or Validity: Free gifts may have expiry dates or
              specific validity periods. Participants must redeem or use their
              free gift(s) within the specified timeframe, as outlined in the
              relevant Promotion Terms and Conditions. Expired or unused free
              gifts will not be replaced or compensated.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.9.Limitation: We reserve the right to limit the number of free
              gifts available for distribution during a Promotion. Free gifts
              will be provided on a first-come, first-served basis until the
              specified limit is reached or stocks run out.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              8.Discounts
            </div>
            <div class="mt_10" style="margin-left: 20px">
              8.1.Where a Promotion for a discount is offered on a particular
              product up to a stated percentage, the discount given will only be
              applicable to qualifying products and the percentage of discount
              given may vary between qualifying products.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              8.2.Supplementary charges such as delivery, supply or installation
              shall not be discounted unless specifically stated in the
              Promotion.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              8.3.Refunds for goods purchased under a promotional offer will be
              based on the terms of the Promotion price. All participant’s
              statutory rights remain.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              9.Copyright and Intellectual Property
            </div>
            <div class="mt_10" style="margin-left: 20px">
              9.1.Our Terms and Conditions in respect of Copyright and
              Intellectual Property apply at all times to all of our marketing
              promotional material and are available
              <a
                href="/TCONLINE"
                target="top"
                >here</a
              >.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              9.2.By participating in a Promotion, participants grant us the
              right to use their name, likeness, and any content shared during
              the Promotion for any future promotional purposes and in any
              promotional material without further compensation.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              10.Data Privacy
            </div>
            <div class="mt_10" style="margin-left: 25px">
              10.1.Our Privacy Policy governs the collection, use and disclosure
              of your personal information by us. The Privacy Policy forms a
              part of these Terms & Conditions and is available
              <a href="/Privacypolicy" target="top"
                >here</a
              >.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              11.Limitation of Liability
            </div>
            <div class="mt_10" style="margin-left: 25px">
              11.1.Except for any liability that cannot be excluded by law, we
              (including our officers, employees and agents) exclude all
              liability (including negligence), for any personal injury; or any
              loss or damage (including loss of opportunity); whether direct,
              indirect, special or consequential, arising in any way out of any
              Promotion, including, but not limited to, where arising out of the
              following: (a) any technical difficulties or equipment malfunction
              (whether or not under our control); (b) any theft, unauthorised
              access or third party interference; (c) any free gift that is
              late, lost, altered, damaged or misdirected due to any reason
              beyond our reasonable control; (d) any variation in gift value to
              that stated in these Terms and Conditions or the Promotion; (e)
              any tax liability/implications incurred by a claimant; or (f) use
              of a free gift.
            </div>
            <div class="mt_10" style="margin-left: 25px">
              11.2.We nor any of our employees, agents, directors and
              contractors, or associated entities shall be liable for any loss
              or claim, action, demand, liability, damage, cost,11.2.expense or
              personal injury whatsoever (including but not limited to any
              direct, indirect or consequential loss), incurred, suffered or
              sustained by any person or entity (without limitation) in
              connection with, or arising from, the Promotion or acceptance of a
              free gift, except that which cannot be excluded by law (in which
              case that liability is limited to the maximum extent allowable by
              law).
            </div>
            <div class="mt_10" style="margin-left: 25px">
              11.3.Any attempt to deliberately undermine the legitimate
              operation of a Promotion may be a violation of criminal and civil
              laws and should such an attempt be made, whether successful or
              not, we reserve the right to refer the matter to law enforcement
              authorities and to seek damages or such other civil remedies as we
              may determine from time to time to the fullest extent permitted by
              law.
            </div>
            <div class="mt_10" style="margin-left: 25px">
              11.4.Failure by us to enforce any of our rights at any stage does
              not constitute a waiver of any of our rights.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              12.Modification of Terms
            </div>
            <div class="mt_10" style="margin-left: 25px">
              12.1.We reserve the right to modify, suspend, or terminate any
              Promotion or these Terms and Conditions at any time without prior
              notice, subject to directions from any relevant authority.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              13.General Disclaimer
            </div>
            <div class="mt_10" style="margin-left: 25px">
              13.1.The participant acknowledges that we do not make any terms,
              guarantees, warranties, representations or conditions whatsoever
              regarding any Promotion other than provided for pursuant to these
              Terms and Conditions.
            </div>
            <div class="mt_10" style="margin-left: 25px">
              13.2.Whilst we make every effort to ensure a product or offer is
              accurately depicted as part of the Promotion, the participant
              acknowledges that sizes, colours and packaging may differ from
              what is advertised in the Promotion.
            </div>
            <div class="mt_10" style="margin-left: 25px">
              13.3.Nothing in these Terms and Conditions is intended to limit or
              exclude any guarantees, warranties, representations or conditions
              implied or imposed by law, including the Australian Consumer Law
              (or any liability under them) which by law may not be limited or
              excluded.
            </div>
            <div class="mt_10" style="margin-left: 25px">
              13.4.Entry into a Promotion, or use of the Website, the Purchase
              Services, and any of the products of us (including the Delivery
              Services), is at your own risk.
            </div>
            <div class="mt_10" style="margin-left: 25px">
              13.5.To the extent permitted by law:
            </div>
            <div class="mt_10" style="margin-left: 60px">
              13.5.1.all terms, guarantees, warranties, representations or
              conditions which are not expressly stated in these Terms and
              Conditions are excluded; and.
            </div>
            <div class="mt_10" style="margin-left: 60px">
              13.5.2.we will not be liable for any special, indirect or
              consequential loss or damage (unless such loss or damage is
              reasonably foreseeable resulting from our failure to meet an
              applicable consumer guarantee), loss of profit or opportunity, or
              damage to goodwill arising out of or in connection with a
              Promotion, whether at common law, under contract, tort (including
              negligence), in equity, pursuant to statute or otherwise.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              In all other respects our Terms and Conditions apply in their
              entirety.
            </div>
            <!-- <div class="header">
          <div class="top_logo">
            <img src="./img/mini_toplogo.jpg" style="height: 30px">
          </div>
        </div> -->
            <!-- <div class="home_form_item radius_top" style=" line-height: 30px;">
          <div>
            <div style="font-weight: 600; font-size: 17px; padding: 10px 20px">
              <span>Customer Information</span>
            </div>
          </div>
        </div>   -->
          </div></div>
        </div>
        <!-- <img style="width: 100%" src="../assets/imgthree/PC/pp.jpg" alt="" /> -->
      </div>

      <div v-else style="margin-bottom: 20px">
        <div style="display: flex; justify-content: center">
         <div
            style="
              width: 76%;
              border: 1px solid #ccc;
              border-radius: 8px;
              padding: 10px;
            "
          >
            <div
              style="font-weight: 700; text-align: center; font-size: 25px"
              class="mt_20"
            >
              Terms and Conditions for Online Marketing Campaigns
            </div>
            <div style="text-align:left">
            <div style="font-weight: 700; font-size: 17px" class="mt_20">
              1.Introduction
            </div>

            <div class="mt_10" style="margin-left: 20px">
              1.1.These terms and conditions along with our Website Terms and
              Conditions of Use and Privacy Policy along with any further terms
              and conditions deemed required by us (collectively “<span
                style="font-weight: 700; font-size: 17px"
                >Terms and Conditions</span
              >”) form the terms and conditions governing your participation in
              any online marketing promotion (“<span
                style="font-weight: 700; font-size: 17px"
                >Promotion</span
              >”) conducted by MORRIS GROUP AUSTRALIA Pty Ltd trading as Smart
              User (“us/we”). By participating in a Promotion, you acknowledge
              and agree that you have read and agree to be bound by the Terms
              and Conditions.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              1.2.You may also accept the Terms and Conditions by clicking to
              accept or agree to the Terms and Conditions where this option is
              made available to you by us in the user interface.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              2.Participation
            </div>
            <div class="mt_10" style="margin-left: 20px">
              2.1.Participants agree to be bound by and comply with the Terms
              and Conditions at all times.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              2.2.Our decision regarding eligibility, or Promotion participation
              criteria is final and binding.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              3.Promotion
            </div>
            <div class="mt_10" style="margin-left: 20px">
              3.1.We reserve the right in our sole discretion to the fullest
              extent permitted by law to modify, change, extend, withdraw,
              remove or cancel a Promotion at any time subject to any directions
              from any relevant authority, if a Promotion is interfered with in
              any way or is not capable of being conducted as anticipated due to
              any reason beyond our reasonable control subject to relevant State
              or Territory regulations at all times..
            </div>
            <div class="mt_10" style="margin-left: 20px">
              3.2.Each Promotion will have a specified Promotion Period at which
              point the Promotion will expire and no longer be valid. We reserve
              the right to extend or shorten the Promotion Period at our sole
              discretion, subject to relevant State or Territory regulations.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              4.Eligibility
            </div>
            <div class="mt_10" style="margin-left: 20px">
              4.1.Promotional offers may be subject to eligibility criteria,
              including but not limited to where a Promotion is linked to the
              Victorian Energy Upgrades (VEU) program.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              4.2.Eligibility criteria may differ or vary between Promotions and
              a participant must read all Terms and Conditions and eligibility
              criteria carefully before proceeding.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              4.3.Only valid and eligible participants will be accepted to
              participate in a Promotion. We reserve the right to verify
              participant eligibility and disqualify/not honour any participant
              who fails to satisfy the required eligibility criteria at any
              time, at our sole discretion subject to relevant State or
              Territory regulations at all times.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              4.4.The eligibility criteria for a Promotion will be specified as
              required in the respective Promotion materials. Factors such as
              age, location, previous participation, and other relevant
              considerations may determine eligibility.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              4.5.The participant warrants that any information provided to us
              in the course of completing the eligibility process, online or
              otherwise, will always be accurate, correct and up to date.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              4.6.Entry and continued participation in a Promotion is strictly
              subject to and dependent on a participant’s compliance at all
              times with all relevant eligibility criteria and the Terms and
              Conditions.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              5.VEU Program
            </div>
            <div class="mt_10" style="margin-left: 20px">
              5.1.We are an accredited provider under the Victorian Energy
              Upgrades (VEU) program, being a voluntary Victorian government
              initiative that provides incentives for Victorians to make
              energy-efficient improvements to their homes and businesses.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              5.2.Many of our Promotions are linked to the VEU program in which
              specific eligibility criteria of participants apply.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              5.3.Should a Promotion be linked in anyway to the VEU program, a
              participant must satisfy all VEU program eligibility requirements
              prior to being entitled to participate in the Promotion.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              5.4.Access to the VEU program and eligibility requirements can be
              found via the below links:
            </div>
            <div class="mt_10" style="margin-left: 50px">
              5.4.1.
              <a
              class="special-link"
                href="https://www.energy.vic.gov.au/households/victorian-energy-upgrades-for-households/about-the-veu-program"
                target="top"
                >About the VEU Program</a
              >
            </div>
            <div class="mt_10" style="margin-left: 50px">
              5.4.2.
              <a
              class="special-link"
                href="https://www.energy.vic.gov.au/__data/assets/pdf_file/0028/585154/Victorian-Energy-Efficiency-Target-scheme-consumer-factsheet.pdf"
                target="top"
                >Victorian Energy Efficiency Target (VEET) scheme consumer
                factsheet</a
              >
            </div>
            <div class="mt_10" style="margin-left: 50px">
              5.4.3.
              <a
              class="special-link"
                href="https://www.energy.vic.gov.au/households/victorian-energy-upgrades-for-households"
                target="top"
                >Victorian Energy Upgrades for households</a
              >
            </div>
            <div class="mt_10" style="margin-left: 50px">
              5.4.4.<a
              class="special-link"
                href="https://www.esc.vic.gov.au/sites/default/files/documents/Code%20of%20Conduct%20Guideline%20-%20FINAL_0.pdf"
                target="top"
                >VEET Code of Conduct Guideline</a
              >
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              6.Promotions
            </div>
            <div class="mt_10" style="margin-left: 20px">
              6.1.We endeavour to display all information correctly, however we
              reserve the right to correct any errors in respect of a Promotion
              at any time.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              6.2.Unless otherwise specified a Promotion offer cannot be used in
              conjunction with any other offer.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              6.3.We reserve the right to end a Promotion at any time subject to
              relevant State or Territory regulations.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              6.4.To the furthest extent permitted by law, we are not liable for
              any financial loss arising out of the refusal, cancellation or
              withdrawal of any Promotion or any failure or inability of a
              participant to take advantage of a Promotion for any reason.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              7.Free Gifts
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.1.Nature and Specifications: any free gift(s) offered as part of
              a Promotion will be described in the Promotion materials as
              required. The nature, quantity, specifications, and value of the
              free gift(s) may vary between Promotions and are subject to
              availability and while stocks last (subject to extension) with
              each Promotion offer subject to change.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.2.Product exclusion: From time-to-time certain products may be
              excluded from a Promotion and any such exclusion will be notified
              to participants.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.3.Distribution: any free gift(s) must be taken as offered and
              may not be varied. We accept no liability for any variation in the
              value of the free gift(s) at time of receipt. Free gifts will be
              distributed to eligible participants as per our Terms and
              Conditions. We reserve the right to substitute the free gift(s) or
              any element of it, with an alternative of equal or greater value
              and specification if unavailable.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.4.Delivery: We will make reasonable efforts to deliver free
              gifts to eligible participants within a reasonable timeframe.
              Delivery times may vary depending on factors such as shipping
              location and availability of the free gift(s).
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.5.Non-Transferable: Free gifts are non-transferable,
              non-exchangeable, and non-refundable. Participants may not request
              cash or any alternative compensation in lieu of the free gift(s).
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.6.Lost or Damaged Gifts: Participants are responsible for
              providing accurate delivery information to us prior to
              distributing the free gift(s) We provide no guarantee of the
              successful delivery of the free gift(s) and we are not liable in
              any way for any lost, stolen, damaged, or undelivered free gift(s)
              once they have been dispatched for delivery and the participant
              indemnifies and holds us forever harmless against any loss or
              damage incurred 7.6.by the participant should the free gift(s)
              fail to be received.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.7.Taxes and Fees: We accept no responsibility for any tax
              implications relating to the acceptance of any free gift(s).
              Participants are responsible for any applicable taxes, duties, or
              other fees associated with receiving the free gift(s) and should
              seek independent financial advice accordingly. The Participant
              indemnifies us against any such taxes, duties, or other fees in
              their entirety.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.8.Expiry or Validity: Free gifts may have expiry dates or
              specific validity periods. Participants must redeem or use their
              free gift(s) within the specified timeframe, as outlined in the
              relevant Promotion Terms and Conditions. Expired or unused free
              gifts will not be replaced or compensated.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              7.9.Limitation: We reserve the right to limit the number of free
              gifts available for distribution during a Promotion. Free gifts
              will be provided on a first-come, first-served basis until the
              specified limit is reached or stocks run out.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              8.Discounts
            </div>
            <div class="mt_10" style="margin-left: 20px">
              8.1.Where a Promotion for a discount is offered on a particular
              product up to a stated percentage, the discount given will only be
              applicable to qualifying products and the percentage of discount
              given may vary between qualifying products.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              8.2.Supplementary charges such as delivery, supply or installation
              shall not be discounted unless specifically stated in the
              Promotion.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              8.3.Refunds for goods purchased under a promotional offer will be
              based on the terms of the Promotion price. All participant’s
              statutory rights remain.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              9.Copyright and Intellectual Property
            </div>
            <div class="mt_10" style="margin-left: 20px">
              9.1.Our Terms and Conditions in respect of Copyright and
              Intellectual Property apply at all times to all of our marketing
              promotional material and are available
              <a
                href="/TCONLINE"
                target="top"
                >here</a
              >.
            </div>
            <div class="mt_10" style="margin-left: 20px">
              9.2.By participating in a Promotion, participants grant us the
              right to use their name, likeness, and any content shared during
              the Promotion for any future promotional purposes and in any
              promotional material without further compensation.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              10.Data Privacy
            </div>
            <div class="mt_10" style="margin-left: 25px">
              10.1.Our Privacy Policy governs the collection, use and disclosure
              of your personal information by us. The Privacy Policy forms a
              part of these Terms & Conditions and is available
              <a href="/Privacypolicy" target="top"
                >here</a
              >.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              11.Limitation of Liability
            </div>
            <div class="mt_10" style="margin-left: 25px">
              11.1.Except for any liability that cannot be excluded by law, we
              (including our officers, employees and agents) exclude all
              liability (including negligence), for any personal injury; or any
              loss or damage (including loss of opportunity); whether direct,
              indirect, special or consequential, arising in any way out of any
              Promotion, including, but not limited to, where arising out of the
              following: (a) any technical difficulties or equipment malfunction
              (whether or not under our control); (b) any theft, unauthorised
              access or third party interference; (c) any free gift that is
              late, lost, altered, damaged or misdirected due to any reason
              beyond our reasonable control; (d) any variation in gift value to
              that stated in these Terms and Conditions or the Promotion; (e)
              any tax liability/implications incurred by a claimant; or (f) use
              of a free gift.
            </div>
            <div class="mt_10" style="margin-left: 25px">
              11.2.We nor any of our employees, agents, directors and
              contractors, or associated entities shall be liable for any loss
              or claim, action, demand, liability, damage, cost,11.2.expense or
              personal injury whatsoever (including but not limited to any
              direct, indirect or consequential loss), incurred, suffered or
              sustained by any person or entity (without limitation) in
              connection with, or arising from, the Promotion or acceptance of a
              free gift, except that which cannot be excluded by law (in which
              case that liability is limited to the maximum extent allowable by
              law).
            </div>
            <div class="mt_10" style="margin-left: 25px">
              11.3.Any attempt to deliberately undermine the legitimate
              operation of a Promotion may be a violation of criminal and civil
              laws and should such an attempt be made, whether successful or
              not, we reserve the right to refer the matter to law enforcement
              authorities and to seek damages or such other civil remedies as we
              may determine from time to time to the fullest extent permitted by
              law.
            </div>
            <div class="mt_10" style="margin-left: 25px">
              11.4.Failure by us to enforce any of our rights at any stage does
              not constitute a waiver of any of our rights.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              12.Modification of Terms
            </div>
            <div class="mt_10" style="margin-left: 25px">
              12.1.We reserve the right to modify, suspend, or terminate any
              Promotion or these Terms and Conditions at any time without prior
              notice, subject to directions from any relevant authority.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              13.General Disclaimer
            </div>
            <div class="mt_10" style="margin-left: 25px">
              13.1.The participant acknowledges that we do not make any terms,
              guarantees, warranties, representations or conditions whatsoever
              regarding any Promotion other than provided for pursuant to these
              Terms and Conditions.
            </div>
            <div class="mt_10" style="margin-left: 25px">
              13.2.Whilst we make every effort to ensure a product or offer is
              accurately depicted as part of the Promotion, the participant
              acknowledges that sizes, colours and packaging may differ from
              what is advertised in the Promotion.
            </div>
            <div class="mt_10" style="margin-left: 25px">
              13.3.Nothing in these Terms and Conditions is intended to limit or
              exclude any guarantees, warranties, representations or conditions
              implied or imposed by law, including the Australian Consumer Law
              (or any liability under them) which by law may not be limited or
              excluded.
            </div>
            <div class="mt_10" style="margin-left: 25px">
              13.4.Entry into a Promotion, or use of the Website, the Purchase
              Services, and any of the products of us (including the Delivery
              Services), is at your own risk.
            </div>
            <div class="mt_10" style="margin-left: 25px">
              13.5.To the extent permitted by law:
            </div>
            <div class="mt_10" style="margin-left: 60px">
              13.5.1.all terms, guarantees, warranties, representations or
              conditions which are not expressly stated in these Terms and
              Conditions are excluded; and.
            </div>
            <div class="mt_10" style="margin-left: 60px">
              13.5.2.we will not be liable for any special, indirect or
              consequential loss or damage (unless such loss or damage is
              reasonably foreseeable resulting from our failure to meet an
              applicable consumer guarantee), loss of profit or opportunity, or
              damage to goodwill arising out of or in connection with a
              Promotion, whether at common law, under contract, tort (including
              negligence), in equity, pursuant to statute or otherwise.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              In all other respects our Terms and Conditions apply in their
              entirety.
            </div>
            <!-- <div class="header">
          <div class="top_logo">
            <img src="./img/mini_toplogo.jpg" style="height: 30px">
          </div>
        </div> -->
            <!-- <div class="home_form_item radius_top" style=" line-height: 30px;">
          <div>
            <div style="font-weight: 600; font-size: 17px; padding: 10px 20px">
              <span>Customer Information</span>
            </div>
          </div>
        </div>   -->
          </div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      offsetwidth: document.documentElement.clientWidth,
    };
  },
  mounted() {},

  methods: {
    // goinfo() {
    //   // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");
    //   this.$emit("fun", "/CustomerDetails");
    // },
  },
};
</script>

<style scoped>
.special-link {  
  text-decoration: underline;  
}
a:link {  
  color: blue;  
}  
/* 已访问的链接 */  
a:visited {  
  color: purple;  
}  
  
/* 鼠标悬浮在链接上 */  
a:hover {  
  color: red;  
}  
  
/* 链接被点击时 */  
a:active {  
  color: yellow;  
}
.mt_20 {
  margin-top: 20px;
}
.mt_10 {
  margin-top: 10px;
}
.text_bold {
  font-weight: 700;
}
</style>
