<template>
  <div v-title :data-title="$route.name">
    <!-- style=" border-style: solid; border-width: 5px; border-color: transparent;" -->
    <div
      v-if="offsetwidth > 850"
      style="
        width: 100%;
        overflow: hidden;
        position: relative;
        min-width: 1349px;
        margin-top: 65px;
      "
    >
      <img style="width: 100%" src="../assets/img/imgs/相册PC顶部.jpg" alt="" />
       
      <div style="width: 100%; text-align: center;">
        <div
          style="
            position: relative;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
          "
        >
          <div style="width: 1200px">
            <el-image
              v-for="(img, index) in picUrlArr"
              :key="index"
              :src="img"
              style="width: 300px; height: 300px"
              :preview-src-list="[img]"
            ></el-image>
          </div>
        </div>
      </div>
        <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/PC/底部PC.jpg"
          alt=""
        />
        <div>
         <div v-if="offsetwidth<1500">
        <span
            style="
              position: absolute;
              top: 43.1%;
              left: 45.4%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              color:white;font-size:12px !important
            "
            @click="goto('PRODUCTS')"
            >PRODUCTS</span
          >
          <span
            style="
              position: absolute;
              top: 48.8%;
              left: 44.9%;
              cursor: pointer;
              transform: translate(-50%, -50%);
                color:white;
                font-size:12px !important
            "
            @click="goto('GALLERY')"
            >GALLERY</span
          >
          <span
            style="
              position: absolute;
              top: 53.8%;
             left: 45.7%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
            @click="goto('ContactUs')"
            >CONTACT US</span
          >
          <span
            style="
              position: absolute;
              top: 59%;
             left: 45.3%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
              @click="goto('BOOKNOW')"
            >BOOK NOW</span
          >
          <!-- <span
            style="
              position: absolute;
              top: 63.9%;
              left: 46.4%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
             @click="goto('privacyPolicy')"
            >PRIVACY POLICY</span
          > -->
            <span
            style="
              position: absolute;
              top: 43%;
              left: 58.2%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
             @click="goto('AIR')"
            >AIR CONDITIONERS & REBATE</span
          ></div>
          <div v-else>
   <span
            style="
              position: absolute;
              top: 43.1%;
              left: 44.9%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              color:white;font-size:12px !important
            "
            @click="goto('PRODUCTS')"
            >PRODUCTS</span
          >
          <span
            style="
              position: absolute;
              top: 48.8%;
              left: 44.5%;
              cursor: pointer;
              transform: translate(-50%, -50%);
                color:white;
                font-size:12px !important
            "
            @click="goto('GALLERY')"
            >GALLERY</span
          >
          <span
            style="
              position: absolute;
              top: 53.8%;
             left: 45.1%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
            @click="goto('ContactUs')"
            >CONTACT US</span
          >
          <span
            style="
              position: absolute;
              top: 59%;
             left: 44.9%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
              @click="goto('BOOKNOW')"
            >BOOK NOW</span
          >
          <!-- <span
            style="
              position: absolute;
              top: 63.9%;
              left: 45.7%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
             @click="goto('privacyPolicy')"
            >PRIVACY POLICY</span
          > -->
            <span
            style="
              position: absolute;
              top: 43%;
              left: 56.8%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
             @click="goto('AIR')"
            >AIR CONDITIONERS & REBATE</span
          >
          </div>
        </div>
        <!-- <img
          style="
            width: 20%;
            position: absolute;
            top: 60%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          src="../assets/imgthree/PCNEW/BookNowButton.png"
          alt=""
        /> -->
      </div>
    </div>
     <div
    v-else
      style="
        width: 100%;
         border: 1px solid #f8f9fa;
        overflow: hidden;
        position: relative;margin-top: 65px
      "
    >
      <img style="width: 100%" src="../assets/img/imgs/Gallerh5.png" alt="" />
  
        <el-row :gutter="0" class="image-row">
    <el-col :span="12" v-for="(img, index) in picUrlArr" :key="index" class="image-col">
      <el-image
        :src="img"
        style="width: 100%; height: 180px;"
        :preview-src-list="[img]"
      ></el-image>
    </el-col></el-row>
     
        <div style="position: relative">
        <img
          style="width: 100%"
           src="../assets/imgthree/H5/底部H5.jpg"
          alt=""
        />
        <div>
          <span
            style="
                 position: absolute;
    top: 43.5%;
    left: 15%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
            @click="goto('PRODUCTS')"
            >PRODUCTS</span
          >
          <span
            style="
                position: absolute;
    top: 46%;
    left: 13.5%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
            @click="goto('GALLERY')"
            >GALLERY</span
          >
          <span
            style="
              position: absolute;
    top: 48.4%;
    left: 16.5%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
            @click="goto('ContactUs')"
            >CONTACT US</span
          >
          <span
            style="
               position: absolute;
    top: 50.6%;
    left: 15.2%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
              @click="goto('BOOKNOW')"
            >BOOK NOW</span
          >
          <!-- <span
            style="
                  position: absolute;
    top: 53.1%;
    left: 19.8%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
             @click="goto('privacyPolicy')"
            >PRIVACY POLICY</span
          > -->
            <span
            style="
              position: absolute;
              top: 71%;
              left: 30%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              font-weight: 700;
                color:white
            "
             @click="goto('AIR')"
            >AIR CONDITIONERS & REBATE</span
          >
        </div>
     
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  data() {
    return {
      picUrlArr: [
        require("../assets/img/imgs/001.jpg"),
        require("../assets/img/imgs/002.jpg"),
        require("../assets/img/imgs/003.jpg"),
        require("../assets/img/imgs/004.jpg"),
        require("../assets/img/imgs/005.jpg"),
        require("../assets/img/imgs/006.jpg"),
        require("../assets/img/imgs/007.jpg"),
        require("../assets/img/imgs/008.jpg"),
        require("../assets/img/imgs/009.jpg"),
        require("../assets/img/imgs/010.jpg"),
        require("../assets/img/imgs/011.jpg"),
        require("../assets/img/imgs/012.jpg"),
        require("../assets/img/imgs/013.jpg"),
        require("../assets/img/imgs/014.jpg"),
        require("../assets/img/imgs/015.jpg"),
        require("../assets/img/imgs/016.jpg"),
      ],
    };
  },
  mounted() {
    document.documentElement.scrollTop = 0;
  },

  methods: {
    goto(name) {
      if (name == "PRODUCTS"||name=="AIR") {
        this.$router.push("/Products");
      } else if (name == "GALLERY") {
        this.$router.push("/ProjectGallery");
      } else if (name == "ContactUs") {
        this.$router.push("/ContactUscopy");
      } else if (name == "BOOKNOW") {
        this.$router.push("/CustomerDetails");
        // window.open("https://smartuser.com.au/customerdetails/");
        // this.$router.push("/ProjectGallery");
      } else if (name == "privacyPolicy") {
        this.$router.push("/privacyPolicy");
      }
    },
    // goinfo() {
    //   // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");
    //   this.$emit("fun", "/CustomerDetails");
    // },
  },
};
</script>

<style scoped>
.image-row {
  /* 这里可以添加额外的行样式，但通常 <el-row> 会有默认的样式 */
  margin: 0; /* 去除行容器的外边距 */
}
 
.image-col {
  /* 由于我们使用了 :gutter="0"，这里通常不需要额外的间距样式 */
  padding: 0; /* 去除列容器的内边距（虽然 <el-col> 默认可能没有内边距） */
}
 
.image-container {
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  gap: 0; /* 设置图片之间的间距为0 */
  margin: 0; /* 去除容器自身的外边距 */
  padding: 0; /* 去除容器自身的内边距 */
}
 
.image-container > el-image {
  flex: 0 0 calc(50% - 0px); /* 每列占据50%的宽度，0px是调整间距的，这里不需要额外间距 */
  box-sizing: border-box; /* 确保内边距和边框包含在宽度内 */
}

.vc_custom_1627269981297copy {
  /* margin-bottom: -40px !important; */
  padding-top: 50px !important;
  padding-bottom: 40px !important;
  background-color: #84b903 !important;
}
/* .vc_custom_1626061515335 {
  margin-bottom: -40px !important;
  padding-top: 50px !important;
  padding-bottom: 40px !important;
  background-color: #84b903 !important;
} */
/* .special-link {
  text-decoration: underline;
}
a:link {
  color: blue;
} */
/* 已访问的链接 */
/* a:visited {
  color: purple;
} */

/* 鼠标悬浮在链接上 */
/* a:hover {
  color: red;
} */

/* .mt_20 {
  margin-top: 20px;
}
.mt_10 {
  margin-top: 10px;
}
.text_bold {
  font-weight: 700;
}
.pcss {
  font-family: Poppins;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
}
.titlecss {
  font-family: Poppins;
  font-style: normal;
} */
</style>
