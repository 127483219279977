<template>
  <div v-title :data-title="$route.name">
    <div
      v-if="offsetwidth > 850"
      style="
        width: 100%;
        overflow: hidden;
        position: relative;
        min-width: 1349px;
        margin-top: 65px;
      "
    >
      <div
        style="
         background-color: rgb(134, 136, 135);
    color: white;
    font-size: 17px;
    display: flex;
   position: relative;
        "
      >
        <div  v-if="pro == '170'" style="
    margin-left: 23vw;">
          Our Projects/EMV-V170W/N1
        </div>
        <div  v-if="pro == '200'" style="
    margin-left: 23vw;">
          Our Projects/EMV-V200W/N1
        </div>
        <div  v-if="pro == '230'" style="
    margin-left: 23vw;">
          Our Projects/EMV-V230W/N1
        </div>
      </div>
      <div style="position: relative">
        <img
          v-if="pro == '170'"
          style="width: 100%"
          src="../assets/imgthree/PC/2-2-170.jpg"
          alt=""
        />
        <img
          v-if="pro == '200'"
          style="width: 100%"
          src="../assets/imgthree/PC/2-2-200.jpg"
          alt=""
        />
        <img
          v-if="pro == '230'"
          style="width: 100%"
          src="../assets/imgthree/PC/2-2-230.jpg"
          alt=""
        />
        <button
          @click="downloadPdf"
          style="
            position: absolute;
            left: 46%;
            bottom: 20px;
            padding: 10px 20px;
            background-color: rgb(105, 154, 255);
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 16px;
          "
        >
          DOWNLOAD PDF
        </button>
      </div>
      <a
        ref="downloadLink"
        style="display: none"
        :href="pdfUrl"
        :download="pdfName"
      ></a>
      <div style="background-color: white" class="parent-container">
        <div class="containercopy" v-if="pro == '170'">
          <div
            class="span-item"
            v-for="(item, index) in items"
            :key="index"
            :class="{ active: activeIndex === index }"
            @click="setActive(index)"
          >
            {{ item.label }}
          </div>
        </div>

        <div class="containercopy" v-if="pro == '200'">
          <div
            class="span-item"
            v-for="(item, index) in items1"
            :key="index"
            :class="{ active: activeIndex === index }"
            @click="setActive(index)"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="containercopy" v-if="pro == '230'">
          <div
            class="span-item"
            v-for="(item, index) in items2"
            :key="index"
            :class="{ active: activeIndex === index }"
            @click="setActive(index)"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
      <!-- 图片展示区域 -->
      <div class="image-container">
        <img :src="activeImage" alt="Active Image" v-if="activeImage" />
      </div>

      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/PC/底部PC.jpg"
          alt=""
        />
        <div>
          <div v-if="offsetwidth < 1500">
            <span
              style="
                position: absolute;
                top: 43.1%;
                left: 45.4%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 12px !important;
              "
              @click="goto('PRODUCTS')"
              >PRODUCTS</span
            >
            <span
              style="
                position: absolute;
                top: 48.8%;
                left: 44.9%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 12px !important;
              "
              @click="goto('GALLERY')"
              >GALLERY</span
            >
            <span
              style="
                position: absolute;
                top: 53.8%;
                left: 45.7%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                font-size: 12px !important;
                color: white;
              "
              @click="goto('ContactUs')"
              >CONTACT US</span
            >
            <span
              style="
                position: absolute;
                top: 59%;
                left: 45.3%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                font-size: 12px !important;
                color: white;
              "
              @click="goto('BOOKNOW')"
              >BOOK NOW</span
            >
            <!-- <span
            style="
              position: absolute;
              top: 63.9%;
              left: 46.4%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
             @click="goto('privacyPolicy')"
            >PRIVACY POLICY</span
          > -->
            <span
              style="
                position: absolute;
                top: 43%;
                left: 58.2%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                font-size: 12px !important;
                color: white;
              "
              @click="goto('AIR')"
              >AIR CONDITIONERS & REBATE</span
            >
          </div>
          <div v-else>
            <span
              style="
                position: absolute;
                top: 43.1%;
                left: 44.9%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 12px !important;
              "
              @click="goto('PRODUCTS')"
              >PRODUCTS</span
            >
            <span
              style="
                position: absolute;
                top: 48.8%;
                left: 44.5%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 12px !important;
              "
              @click="goto('GALLERY')"
              >GALLERY</span
            >
            <span
              style="
                position: absolute;
                top: 53.8%;
                left: 45.1%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                font-size: 12px !important;
                color: white;
              "
              @click="goto('ContactUs')"
              >CONTACT US</span
            >
            <span
              style="
                position: absolute;
                top: 59%;
                left: 44.9%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                font-size: 12px !important;
                color: white;
              "
              @click="goto('BOOKNOW')"
              >BOOK NOW</span
            >
            <!-- <span
            style="
              position: absolute;
              top: 63.9%;
              left: 45.7%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
             @click="goto('privacyPolicy')"
            >PRIVACY POLICY</span
          > -->
            <span
              style="
                position: absolute;
                top: 43%;
                left: 56.8%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                font-size: 12px !important;
                color: white;
              "
              @click="goto('AIR')"
              >AIR CONDITIONERS & REBATE</span
            >
          </div>
        </div>
        <!-- <img
          style="
            width: 20%;
            position: absolute;
            top: 60%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          src="../assets/imgthree/PCNEW/BookNowButton.png"
          alt=""
        /> -->
      </div>
    </div>

    <div
      v-else
      style="
        width: 100%;
        border: 1px solid #f8f9fa;
        overflow: hidden;
        position: relative;
        margin-top: 65px;
      "
    >
   <div
        style="
         background-color: rgb(134, 136, 135);
    color: white;
    font-size: 17px;
    display: flex;
   position: relative;
        "
      >
        <div  v-if="pro == '170'" style="
    margin-left: 27px;">
          Our Projects/EMV-V170W/N1
        </div>
        <div  v-if="pro == '200'" style="
    margin-left: 27px;"> 
          Our Projects/EMV-V200W/N1
        </div>
        <div  v-if="pro == '230'" style="
    margin-left: 27px;">
          Our Projects/EMV-V230W/N1
        </div>
      </div>
 <!-- <div style="position: relative"> -->
        <img
          v-if="pro == '170'"
          style="width: 100%"
          src="../assets/imgthree/H5/2产品介绍170.jpg"
          alt=""
        />
        <img
          v-if="pro == '200'"
          style="width: 100%"
          src="../assets/imgthree/H5/2产品介绍200.jpg"
          alt=""
        />
        <img
          v-if="pro == '230'"
          style="width: 100%"
          src="../assets/imgthree/H5/2产品介绍230.jpg"
          alt=""
        />
        <div style="width:100%;background-color:white;text-align:left;height:60px">
          <button
          @click="downloadPdf"
          style="
           margin-left:16px;
            padding: 10px 20px;
            background-color: rgb(105, 154, 255);
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 16px;
          "
        >
          DOWNLOAD PDF
        </button></div>
           <a
        ref="downloadLink"
        style="display: none"
        :href="pdfUrl"
        :download="pdfName"
      ></a>

      <div style="background-color: white" class="parent-container">
        <div class="containercopy" v-if="pro == '170'">
          <div
            class="span-item"
            v-for="(item, index) in itemsh5"
            :key="index"
            :class="{ active: activeIndex === index }"
            @click="setActive(index)"
          >
            {{ item.label }}
          </div>
        </div>

        <div class="containercopy" v-if="pro == '200'">
          <div
            class="span-item"
            v-for="(item, index) in items1h5"
            :key="index"
            :class="{ active: activeIndex === index }"
            @click="setActive(index)"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="containercopy" v-if="pro == '230'">
          <div
            class="span-item"
            v-for="(item, index) in items2h5"
            :key="index"
            :class="{ active: activeIndex === index }"
            @click="setActive(index)"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
        <!-- 图片展示区域 -->
      <div class="image-container">
        <img :src="activeImagecopy" alt="Active Image" v-if="activeImagecopy" />
      </div>
        <!-- <button
          @click="downloadPdf"
          style="
            position: absolute;
            left: 46%;
            bottom: 20px;
            padding: 10px 20px;
            background-color: rgb(105, 154, 255);
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 16px;
          "
        >
          DOWNLOAD PDF
        </button> -->
      <!-- </div> -->


      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/H5/底部H5.jpg"
          alt=""
        />
        <div>
          <span
            style="
              position: absolute;
              top: 43.5%;
              left: 15%;
              cursor: pointer;
              font-weight: 700;
              transform: translate(-50%, -50%);
              color: white;
            "
            @click="goto('PRODUCTS')"
            >PRODUCTS</span
          >
          <span
            style="
              position: absolute;
              top: 46%;
              left: 13.5%;
              cursor: pointer;
              font-weight: 700;
              transform: translate(-50%, -50%);
              color: white;
            "
            @click="goto('GALLERY')"
            >GALLERY</span
          >
          <span
            style="
              position: absolute;
              top: 48.4%;
              left: 16.5%;
              cursor: pointer;
              font-weight: 700;
              transform: translate(-50%, -50%);
              color: white;
            "
            @click="goto('ContactUs')"
            >CONTACT US</span
          >
          <span
            style="
              position: absolute;
              top: 50.6%;
              left: 15.2%;
              cursor: pointer;
              font-weight: 700;
              transform: translate(-50%, -50%);
              color: white;
            "
            @click="goto('BOOKNOW')"
            >BOOK NOW</span
          >
          <!-- <span
            style="
                  position: absolute;
    top: 53.1%;
    left: 19.8%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
             @click="goto('privacyPolicy')"
            >PRIVACY POLICY</span
          > -->
          <span
            style="
              position: absolute;
              top: 71%;
              left: 30%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              font-weight: 700;
              color: white;
            "
            @click="goto('AIR')"
            >AIR CONDITIONERS & REBATE</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  data() {
    return {
      pro: "170",
      items: [
        {
          label: "Introduction",
          image: require("../assets/imgthree/PC/2内容.jpg"),
        },
        {
          label: "Key Features",
          image: require("../assets/imgthree/PC/2特点PC.jpg"),
        },
        {
          label: "Specifications",
          image: require("../assets/imgthree/PC/2参数170.jpg"),
        },
      ],
      items1: [
        {
          label: "Introduction",
          image: require("../assets/imgthree/PC/2内容.jpg"),
        },
        {
          label: "Key Features",
          image: require("../assets/imgthree/PC/2特点PC.jpg"),
        },
        {
          label: "Specifications",
          image: require("../assets/imgthree/PC/2参数200.jpg"),
        },
      ],
      items2: [
        {
          label: "Introduction",
          image: require("../assets/imgthree/PC/2内容.jpg"),
        },
        {
          label: "Key Features",
          image: require("../assets/imgthree/PC/2特点PC.jpg"),
        },
        {
          label: "Specifications",
          image: require("../assets/imgthree/PC/2参数230.jpg"),
        },
      ],
        itemsh5: [
         {
          label: "Introduction",
          image: require("../assets/imgthree/H5/2产品介绍内容1.jpg"),
        },
        {
          label: "Key Features",
          image: require("../assets/imgthree/H5/2产品介绍特点H5.jpg"),
        },
        {
          label: "Specifications",
          image: require("../assets/imgthree/H5/2产品参数170.jpg"),
        },
      ],
       items1h5: [
        {
          label: "Introduction",
          image: require("../assets/imgthree/H5/2产品介绍内容1.jpg"),
        },
        {
          label: "Key Features",
          image: require("../assets/imgthree/H5/2产品介绍特点H5.jpg"),
        },
        {
          label: "Specifications",
          image: require("../assets/imgthree/H5/2产品参数200.jpg"),
        },
      ],
        items2h5: [
         {
          label: "Introduction",
          image: require("../assets/imgthree/H5/2产品介绍内容1.jpg"),
        },
        {
          label: "Key Features",
          image: require("../assets/imgthree/H5/2产品介绍特点H5.jpg"),
        },
        {
          label: "Specifications",
          image: require("../assets/imgthree/H5/2产品参数230.jpg"),
        },
      ],
      activeIndex: 0, // 默认选中第一个 span-item
      pdfUrl: "/Siliconelectronics.pdf",
      // 下载时文件的默认名称
      pdfName: "Siliconelectronics.pdf",
    };
  },
  computed: {
      activeImagecopy() {
      if (this.pro == "170") {
        return this.itemsh5[this.activeIndex].image;
      } else if (this.pro == "200") {
        return this.items1h5[this.activeIndex].image;
      } else if (this.pro == "230") {
        return this.items2h5[this.activeIndex].image;
      }
      return "";
    },
    // 获取当前选中的内容
    activeImage() {
      if (this.pro == "170") {
        return this.items[this.activeIndex].image;
      } else if (this.pro == "200") {
        return this.items1[this.activeIndex].image;
      } else if (this.pro == "230") {
        return this.items2[this.activeIndex].image;
      }
      return "";
    },
  },
  mounted() {
    this.pro = window.sessionStorage.getItem("pro");
    // window.fbq("track", "ViewContent", {
    //   content_type: "Contact Us",
    //   content_ids: ["10005"],
    //   content_name: "Contact Us",
    //   content_category: "Contact Us",
    // });
  },
  methods: {
    setActive(index) {
      this.activeIndex = index;
    },
    downloadPdf() {
      // 触发隐藏链接的点击事件
      this.$refs.downloadLink.click();
    },
    gotoproductcentent(name) {
      this.$router.push({ name: "Productscontent", params: { pro: name } });
    },
    goto(name) {
      if (name == "PRODUCTS" || name == "AIR") {
        this.$router.push("/Products");
      } else if (name == "GALLERY") {
        this.$router.push("/ProjectGallery");
      } else if (name == "ContactUs") {
        this.$router.push("/ContactUscopy");
      } else if (name == "BOOKNOW") {
        this.$router.push("/CustomerDetails");
        // window.open("https://smartuser.com.au/customerdetails/");
        // this.$router.push("/ProjectGallery");
      } else if (name == "privacyPolicy") {
        this.$router.push("/privacyPolicy");
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  justify-content: center; /* 整体居中 */
  background-color: white;
  margin-bottom: 20px;
  position: relative;
}

.image-wrapper {
  position: relative; /* 使按钮相对于图片定位 */
  width: 30%; /* 设置图片宽度 */
  margin: 0 1%; /* 图片之间的间距 */
}

.image-item {
  width: 100%;
  display: block; /* 确保图片是块级元素 */
}

.image-button {
  position: absolute; /* 绝对定位 */
  bottom: 40px; /* 增加按钮距离图片底部的距离 */
  left: 51.5%; /* 水平居中 */
  transform: translateX(-50%); /* 水平居中 */
  padding: 12px 24px; /* 增加按钮内边距，使按钮更大 */
  width: 200px; /* 设置按钮宽度 */
  background-color: RGB(104, 153, 254);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 17px;
  text-align: center; /* 文字居中 */
}

.image-button:hover {
  background-color: #0056b3; /* 鼠标悬停效果 */
}

.image-wrapper.left {
  margin-left: 10%; /* 第一个图片距离左侧的距离 */
}

.image-wrapper.right {
  margin-right: 10%; /* 第三个图片距离右侧的距离 */
}
.parent-container {
  width: 100%; /* 父元素铺满页面宽度 */
  padding: 0; /* 移除内边距 */
  margin: 0; /* 移除外边距 */
}
.containercopy {
  width: 100%; /* 铺满页面宽度 */
  display: flex;
  justify-content: center; /* 让内容居中 */
  border-bottom: 2px solid rgb(47, 47, 130); /* 底部横线 */
  padding-bottom: 10px;
  margin-bottom: 10px;
  background-color: white; /* 背景为白色 */
  position: relative;
}

/* span-item 样式 */
.span-item {
  width: 200px; /* 设置固定宽度 */
  text-align: center;
  position: relative;
  cursor: pointer;
  margin: 0 10px; /* 添加间距 */
  transition: font-weight 0.3s ease; /* 字体变化动画 */
  font-size: 17px;
}

/* 竖条样式 */
.span-item:not(:last-child)::after {
  content: "";
  position: absolute;
  right: -10px; /* 调整竖条位置 */
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 2px;
  background-color: rgb(191, 191, 191); /* 竖条颜色 */
}

/* 选中状态的 span-item 样式 */
.span-item.active {
  font-weight: bold; /* 字体加粗 */
  color: rgb(47, 47, 130); /* 字体颜色 */
}

/* 内容展示区域样式 */
.content {
  padding: 10px;
  border: 1px solid #ccc;
  display: none;
  text-align: center;
}

.content.active {
  display: block;
}
</style>
