<template>
  <div v-title :data-title="$route.name">
    <div
      v-if="offsetwidth > 850"
      style="
        width: 100%;
        overflow: hidden;
        position: relative;
        min-width: 1349px;
        margin-top: 65px;
      "
    >
      <img style="width: 100%" src="../assets/imgthree/PC/contactus.jpg" alt="" />
         <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgthree/PC/底部PC.jpg"
          alt=""
        />
        <div>
         <div v-if="offsetwidth<1500">
        <span
            style="
              position: absolute;
              top: 43.1%;
              left: 45.4%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              color:white;font-size:12px !important
            "
            @click="goto('PRODUCTS')"
            >PRODUCTS</span
          >
          <span
            style="
              position: absolute;
              top: 48.8%;
              left: 44.9%;
              cursor: pointer;
              transform: translate(-50%, -50%);
                color:white;
                font-size:12px !important
            "
            @click="goto('GALLERY')"
            >GALLERY</span
          >
          <span
            style="
              position: absolute;
              top: 53.8%;
             left: 45.7%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
            @click="goto('ContactUs')"
            >CONTACT US</span
          >
          <span
            style="
              position: absolute;
              top: 59%;
             left: 45.3%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
              @click="goto('BOOKNOW')"
            >BOOK NOW</span
          >
          <!-- <span
            style="
              position: absolute;
              top: 63.9%;
              left: 46.4%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
             @click="goto('privacyPolicy')"
            >PRIVACY POLICY</span
          > -->
            <span
            style="
              position: absolute;
              top: 43%;
              left: 58.2%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
             @click="goto('AIR')"
            >AIR CONDITIONERS & REBATE</span
          ></div>
          <div v-else>
   <span
            style="
              position: absolute;
              top: 43.1%;
              left: 44.9%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              color:white;font-size:12px !important
            "
            @click="goto('PRODUCTS')"
            >PRODUCTS</span
          >
          <span
            style="
              position: absolute;
              top: 48.8%;
              left: 44.5%;
              cursor: pointer;
              transform: translate(-50%, -50%);
                color:white;
                font-size:12px !important
            "
            @click="goto('GALLERY')"
            >GALLERY</span
          >
          <span
            style="
              position: absolute;
              top: 53.8%;
             left: 45.1%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
            @click="goto('ContactUs')"
            >CONTACT US</span
          >
          <span
            style="
              position: absolute;
              top: 59%;
             left: 44.9%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
              @click="goto('BOOKNOW')"
            >BOOK NOW</span
          >
          <!-- <span
            style="
              position: absolute;
              top: 63.9%;
              left: 45.7%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
             @click="goto('privacyPolicy')"
            >PRIVACY POLICY</span
          > -->
            <span
            style="
              position: absolute;
              top: 43%;
              left: 56.8%;
              cursor: pointer;
              transform: translate(-50%, -50%);font-size:12px !important;
                color:white
            "
             @click="goto('AIR')"
            >AIR CONDITIONERS & REBATE</span
          >
          </div>
        </div>
        <!-- <img
          style="
            width: 20%;
            position: absolute;
            top: 60%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          src="../assets/imgthree/PCNEW/BookNowButton.png"
          alt=""
        /> -->
      </div>
    </div>
    <div
      v-else
      style="
        width: 100%;
        border: 1px solid #f8f9fa;
        overflow: hidden;
        position: relative;
        margin-top: 65px;
      "
    >
      <img style="width: 100%" src="../assets/imgthree/H5/联系我们H5.jpg" alt="" />
           <div style="position: relative">
        <img
          style="width: 100%"
           src="../assets/imgthree/H5/底部H5.jpg"
          alt=""
        />
        <div>
          <span
            style="
                 position: absolute;
    top: 43.5%;
    left: 15%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
            @click="goto('PRODUCTS')"
            >PRODUCTS</span
          >
          <span
            style="
                position: absolute;
    top: 46%;
    left: 13.5%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
            @click="goto('GALLERY')"
            >GALLERY</span
          >
          <span
            style="
              position: absolute;
    top: 48.4%;
    left: 16.5%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
            @click="goto('ContactUs')"
            >CONTACT US</span
          >
          <span
            style="
               position: absolute;
    top: 50.6%;
    left: 15.2%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
              @click="goto('BOOKNOW')"
            >BOOK NOW</span
          >
          <!-- <span
            style="
                  position: absolute;
    top: 53.1%;
    left: 19.8%;
    cursor: pointer;
    font-weight: 700;
    transform: translate(-50%, -50%);
    color: white;
            "
             @click="goto('privacyPolicy')"
            >PRIVACY POLICY</span
          > -->
            <span
            style="
              position: absolute;
              top: 71%;
              left: 30%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              font-weight: 700;
                color:white
            "
             @click="goto('AIR')"
            >AIR CONDITIONERS & REBATE</span
          >
        </div>
     
      </div>
    
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
   return :{
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    // window.fbq("track", "ViewContent", {
    //   content_type: "Contact Us",
    //   content_ids: ["10005"],
    //   content_name: "Contact Us",
    //   content_category: "Contact Us",
    // });
  },
  methods: {
   goto(name){
if(name=="PRODUCTS"||name=="AIR"){
  this.$router.push("/Products");
}else if(name=="GALLERY"){
  this.$router.push("/ProjectGallery")
}else if(name=="ContactUs"){
  this.$router.push("/ContactUscopy")
}else if(name=="BOOKNOW"){
  this.$router.push("/CustomerDetails");
  // window.open('https://smartuser.com.au/customerdetails/')
  // this.$router.push("/ProjectGallery")
}else if(name=="privacyPolicy"){
  this.$router.push("/privacyPolicy")
}
    },
  },
};
</script>

<style scoped>
 
</style>
