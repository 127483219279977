<template>
  <div v-title :data-title="$route.name">
    <!-- style=" border-style: solid; border-width: 5px; border-color: transparent;" -->
    <div ref="myElement" style="background:white;margin-top:65px">
      <div v-if="offsetwidth > 850">
         <div style="display: flex; justify-content: center">
          <div style="width: 100%; text-align: center; padding: 10px">
            <span style="font-weight: 700; font-size: 35px"
              >PRIVACY POLICY</span
            >
          </div>
         </div>
         
        <div style="display: flex; justify-content: center;margin-top: 100px;">
          <div
            style="
              width: 70%;
              padding: 10px;
            "
          >
            <div style="font-weight: 700; font-size: 14px; text-align: center">
              PRIVACY POLICY
            </div>
            <div style="text-align:left">
            <div class="mt_20 pcss">
              Morris Group Australia Pty Ltd ACN 152 141 298 trading as Smart
              User
            </div>
            <div class="mt_20 titlecss" style="font-weight: 700">
              1. We respect your privacy
            </div>
            <div class="mt_20 pcss">
              (a) Morris Group Australia Pty Ltd respects your right to privacy
              and is committed to safeguarding the privacy of our customers and
              website visitors. We adhere to the Australian Privacy Principles
              contained in the Privacy Act 1988 (Cth). This policy sets out how
              we collect and treat your personal information.
            </div>
            <div class="mt_20 pcss">
              (b) "Personal information" is information we hold which is
              identifiable as being about you.
            </div>
            <div class="mt_20 titlecss" style="font-weight: 700">
              2. Collection of personal information
            </div>
            <div class="mt_20 pcss">
              (a) Morris Group Australia Pty Ltd will, from time to time,
              receive and store personal information you enter onto our website,
              provided to us directly or given to us in other forms.
            </div>
            <div class="mt_20 pcss">
              (b) You may provide basic information such as your name, phone
              number, address and email address to enable us to send
              information, provide updates and process your product or service
              order. We may collect additional information at other times,
              including but not limited to, when you provide feedback, when you
              provide information about your personal or business affairs,
              change your content or email preference, respond to surveys and/or
              promotions, provide financial or credit card information, or
              communicate with our customer support.
            </div>
            <div class="mt_20 pcss">
              (c) Additionally, we may also collect any other information you
              provide while interacting with us.
            </div>
            <div class="mt_20 titlecss" style="font-weight: 700">
              3. How we collect your personal information
            </div>
            <div class="mt_20 pcss">
              (a) Morris Group Australia Pty Ltd collects personal information
              from you in a variety of ways, including when you interact with us
              electronically or in person, when you access our website and when
              we provide our services to you. We may receive personal
              information from third parties. If we do, we will protect it as
              set out in this Privacy Policy.
            </div>
            <div class="mt_20 titlecss" style="font-weight: 700">
              4. Use of your personal information
            </div>
            <div class="mt_20 pcss">
              (a) Morris Group Australia Pty Ltd may use personal information
              collected from you to provide you with information, updates and
              our services. We may also make you aware of new and additional
              products, services and opportunities available to you. We may use
              your personal information to improve our products and services and
              better understand your needs.
            </div>
            <div class="mt_20 pcss">
              (b) Morris Group Australia Pty Ltd may contact you by a variety of
              measures including, but not limited to telephone, email, sms or
              mail.
            </div>

            <div class="mt_20 titlecss" style="font-weight: 700">
              5. Disclosure of your personal information
            </div>
            <div class="mt_20 pcss">
              (a) We may disclose your personal information to any of our
              employees, officers, insurers, professional advisers, agents,
              suppliers or subcontractors insofar as reasonably necessary for
              the purposes set out in this Policy. Personal informa tion is only
              supplied to a third party when it is required for the delivery of
              our services.
            </div>
            <div class="mt_20 pcss">
              (b) We may from time to time need to disclose personal information
              to comply with a legal requirement, such as a law, regulation,
              court order, subpoena, warrant, in the course of a legal
              proceeding or in response to a law enforcement agency request.
            </div>
            <div class="mt_20 pcss">
              (c) We may also use your personal information to protect the
              copyright, trademarks, legal rights, property or safety of Morris
              Group Australia Pty Ltd, its customers or third parties.
            </div>
            <div class="mt_20 pcss">
              (d) Information that we collect may from time to time be stored,
              processed in or transferred between parties located in countries
              outside of Australia. Such information may include, but is not
              limited to:
            </div>
            <div class="mt_20 pcss">(i) your name;</div>
            <div class="mt_20 pcss">
              (ii) your contact details, including email address, mailing
              address, street address and/or telephone number;
            </div>
            <div class="mt_20 pcss">
              (iii) information you provide to us when submitting an enquiry
              through our contact form;
            </div>
            <div class="mt_20 pcss">(iv) your credit card or payment details;</div>
            <div class="mt_20 pcss">
              (v) details of services we have provided to you and/or that you
              have enquired about, and our response to you;
            </div>
            <div class="mt_20 pcss">
              (vi) your browser session and geo-location data, device and
              network information, statistics on page views and sessions,
              acquisition sources, search queries and/or browsing behaviour;
            </div>
            <div class="mt_20 pcss">
              (vii) information about your access and use of our website,
              including through the use of Internet cookies, your communications
              with our website, the type of browser you are using, the type of
              operating system you are using and the domain name of your
              Internet service provider;
            </div>
            <div class="mt_20 pcss">
              (viii) additional personal information that you provide to us,
              directly or indirectly, through your use of our Site, associated
              applications, accounts from which you permit us to collect
              information, or in the course of providing services to you; and
            </div>
            <div class="mt_20 pcss">
              (ix) any other personal information requested by us and/or
              provided by you or a third party.
            </div>
            <div class="mt_20 pcss">
              (e) If there is a change of control in our business or a sale or
              transfer of business assets, we reserve the right to transfer to
              the extent permissible at law our user databases, together with
              any personal information and non-personal information contained in
              those databases. This information may be disclosed to a potential
              purchaser under an agreement to maintain confidentiality. We would
              seek to only disclose information in good faith and where required
              by any of the above circumstances.
            </div>
            <div class="mt_20 pcss">
              (f) By providing us with personal information, you consent to the
              terms of this Privacy Policy and the types of disclosure covered
              by this Policy. Where we disclose your personal information to
              third parties, we will request that the third party follow this
              Policy regarding handling your personal information.
            </div>
            <div class="mt_20 titlecss" style="font-weight: 700">
              6. Security of your personal information
            </div>
            <div class="mt_20 pcss">
              (a) Morris Group Australia Pty Ltd is committed to ensuring that
              the information you provide to us is secure. In order to prevent
              unauthorised access or disclosure, we have put in place suitable
              physical, electronic and managerial procedures to safeguard and
              secure information and protect it from misuse, interference, loss
              and unauthorised access, modification and disclosure.
            </div>
            <div class="mt_20 pcss">
              (b) The transmission and exchange of information is carried out at
              your own risk. We cannot guarantee the security of any information
              that you transmit to us, or receive from us. Although we take
              measures to safeguard against unauthorised disclosures of
              information, we cannot assure you that personal information that
              we collect will not be disclosed in a manner that is inconsistent
              with this Privacy Policy.
            </div>
            <div class="mt_20 titlecss" style="font-weight: 700">
              7. Access to your personal information
            </div>
            <div class="mt_20 pcss">
              (a) You may request details of personal information that we hold
              about you in accordance with the provisions of the Privacy Act
              1988 (Cth). A small administrative fee may be payable for the
              provision of information. If you would like a copy of the
              information, which we hold about you or believe that any
              information we hold on you is inaccurate, o of date, incomplete,
              irrelevant or misleading, please email us at  <a href="mailto:info@smartuser.com.au" style="color:rgb(9 82 124);" target="_blank">info@smartuser.com.au</a>
            </div>
            <div class="mt_20 pcss">
              (b) We reserve the right to refuse to provide you with information
              that we hold about you, in certain circumstances set out in the
              Privacy Act.
            </div>

             <div class="mt_20 titlecss" style="font-weight: 700">
             8. Complaints about privacy
            </div>
            <div class="mt_20 pcss">
             (a) If you have any complaints about our privacy practises, please feel free to send in details of your complaints to 11 Howleys Road , Notting Hill, Victoria, 3168. We take complaints very seriously and will respond shortly after receiving written notice of your complaint.
            </div>
             <div class="mt_20 titlecss" style="font-weight: 700">
             9. Changes to Privacy Policy
            </div>
            <div class="mt_20 pcss">
            (a) Please be aware that we may change this Privacy Policy in the future. We may modify this Policy at any time, in our sole discretion and all modifications will be effective immediately upon our posting of the modifications on our website or notice board. Please check back from time to time to review our Privacy Policy.
            </div>
             <div class="mt_20 titlecss" style="font-weight: 700">
             10. Website
            </div>
            <div class="mt_20 ">
           (a)<span style="font-weight:700">When you visit our website</span>
            </div>
             <div class="mt_20 pcss">
          When you come to our website <a href="https://smartuser.com.au/" style="color:rgb(9 82 124);" target="_blank">https://smartuser.com.au/</a> we may collect certain information such as browser type, operating system, website visited immediately before coming to our site, etc. This information is used in an aggregated manner to analyse how people use our site, such that we can improve our service.
            </div>
              <div class="mt_20">
           (b)<span style="font-weight:700">Cookies</span>
            </div>
             <div class="mt_20 pcss">
         We may from time to time use cookies on our website. Cookies are very small files which a website uses to identify you when you come back to the site and to store details about your use of the site. Cookies are not malicious programs that access or damage your computer. Most web browsers automatically accept cookies but you can choose to reject cookies by changing your browser settings. However, this may prevent you from taking full advantage of our website. Our website may from time to time use cookies to analyses website traffic and help us provide a better website visitor experience. In addition, cookies may be used to serve relevant ads to website visitors through third party services such as Google Adwords. These ads may appear on this website or other websites you visit.
            </div>

              <div class="mt_20">
           (c)<span style="font-weight:700">Third party sites</span>
            </div>
             <div class="mt_20 pcss">
        Our site may from time to time have links to other websites not owned or controlled by us. These links are meant for your convenience only. Links to third party websites do not constitute sponsorship or endorsement or approval of these websites. Please be aware that Morris Group Australia Pty Ltd is not responsible for the privacy practises of other such websites. We encourage our users to be aware, when they leave our website, to read the privacy statements of each and every website that collects personal identifiable information.
            </div>
              <div class="mt_20 " style="font-weight:700;font-size:15px">
                  For any questions and notices, please contact us at:
            </div>
             <div class="pcss" >
                  MORRIS GROUP AUSTRALIA Pty Ltd ACN 152 141 298 trading as Smart User
            </div>
             <div class="pcss" >
                 Email: 
                 <a href="mailto:info@smartuser.com.au" style="color:rgb(9 82 124);" target="_blank">info@smartuser.com.au</a>

            </div>
             <div  style="font-weight:700;font-size:15px">
                 Telephone: +61 03 8528 1831
            </div>
          </div>
          </div>
        </div>
      </div>

      <div v-else style="margin-bottom: 20px">
          <div style="display: flex; justify-content: center">
          <div style="width: 100%; text-align: center; padding: 10px">
            <span style="font-weight: 700; font-size: 35px"
              >PRIVACY POLICY</span
            >
          </div>
         </div>
        <div style="display: flex; justify-content: center">
           <div
            style="
              width: 90%;
              padding: 10px;
            "
          >
            <div style="font-weight: 700; font-size: 14px; text-align: center">
              PRIVACY POLICY
            </div>
             <div style="text-align:left">
            <div class="mt_20 pcss">
              Morris Group Australia Pty Ltd ACN 152 141 298 trading as Smart
              User
            </div>
            <div class="mt_20 titlecss" style="font-weight: 700">
              1. We respect your privacy
            </div>
            <div class="mt_20 pcss">
              (a) Morris Group Australia Pty Ltd respects your right to privacy
              and is committed to safeguarding the privacy of our customers and
              website visitors. We adhere to the Australian Privacy Principles
              contained in the Privacy Act 1988 (Cth). This policy sets out how
              we collect and treat your personal information.
            </div>
            <div class="mt_20 pcss">
              (b) "Personal information" is information we hold which is
              identifiable as being about you.
            </div>
            <div class="mt_20 titlecss" style="font-weight: 700">
              2. Collection of personal information
            </div>
            <div class="mt_20 pcss">
              (a) Morris Group Australia Pty Ltd will, from time to time,
              receive and store personal information you enter onto our website,
              provided to us directly or given to us in other forms.
            </div>
            <div class="mt_20 pcss">
              (b) You may provide basic information such as your name, phone
              number, address and email address to enable us to send
              information, provide updates and process your product or service
              order. We may collect additional information at other times,
              including but not limited to, when you provide feedback, when you
              provide information about your personal or business affairs,
              change your content or email preference, respond to surveys and/or
              promotions, provide financial or credit card information, or
              communicate with our customer support.
            </div>
            <div class="mt_20 pcss">
              (c) Additionally, we may also collect any other information you
              provide while interacting with us.
            </div>
            <div class="mt_20 titlecss" style="font-weight: 700">
              3. How we collect your personal information
            </div>
            <div class="mt_20 pcss">
              (a) Morris Group Australia Pty Ltd collects personal information
              from you in a variety of ways, including when you interact with us
              electronically or in person, when you access our website and when
              we provide our services to you. We may receive personal
              information from third parties. If we do, we will protect it as
              set out in this Privacy Policy.
            </div>
            <div class="mt_20 titlecss" style="font-weight: 700">
              4. Use of your personal information
            </div>
            <div class="mt_20 pcss">
              (a) Morris Group Australia Pty Ltd may use personal information
              collected from you to provide you with information, updates and
              our services. We may also make you aware of new and additional
              products, services and opportunities available to you. We may use
              your personal information to improve our products and services and
              better understand your needs.
            </div>
            <div class="mt_20 pcss">
              (b) Morris Group Australia Pty Ltd may contact you by a variety of
              measures including, but not limited to telephone, email, sms or
              mail.
            </div>

            <div class="mt_20 titlecss" style="font-weight: 700">
              5. Disclosure of your personal information
            </div>
            <div class="mt_20 pcss">
              (a) We may disclose your personal information to any of our
              employees, officers, insurers, professional advisers, agents,
              suppliers or subcontractors insofar as reasonably necessary for
              the purposes set out in this Policy. Personal informa tion is only
              supplied to a third party when it is required for the delivery of
              our services.
            </div>
            <div class="mt_20 pcss">
              (b) We may from time to time need to disclose personal information
              to comply with a legal requirement, such as a law, regulation,
              court order, subpoena, warrant, in the course of a legal
              proceeding or in response to a law enforcement agency request.
            </div>
            <div class="mt_20 pcss">
              (c) We may also use your personal information to protect the
              copyright, trademarks, legal rights, property or safety of Morris
              Group Australia Pty Ltd, its customers or third parties.
            </div>
            <div class="mt_20 pcss">
              (d) Information that we collect may from time to time be stored,
              processed in or transferred between parties located in countries
              outside of Australia. Such information may include, but is not
              limited to:
            </div>
            <div class="mt_20 pcss">(i) your name;</div>
            <div class="mt_20 pcss">
              (ii) your contact details, including email address, mailing
              address, street address and/or telephone number;
            </div>
            <div class="mt_20 pcss">
              (iii) information you provide to us when submitting an enquiry
              through our contact form;
            </div>
            <div class="mt_20 pcss">(iv) your credit card or payment details;</div>
            <div class="mt_20 pcss">
              (v) details of services we have provided to you and/or that you
              have enquired about, and our response to you;
            </div>
            <div class="mt_20 pcss">
              (vi) your browser session and geo-location data, device and
              network information, statistics on page views and sessions,
              acquisition sources, search queries and/or browsing behaviour;
            </div>
            <div class="mt_20 pcss">
              (vii) information about your access and use of our website,
              including through the use of Internet cookies, your communications
              with our website, the type of browser you are using, the type of
              operating system you are using and the domain name of your
              Internet service provider;
            </div>
            <div class="mt_20 pcss">
              (viii) additional personal information that you provide to us,
              directly or indirectly, through your use of our Site, associated
              applications, accounts from which you permit us to collect
              information, or in the course of providing services to you; and
            </div>
            <div class="mt_20 pcss">
              (ix) any other personal information requested by us and/or
              provided by you or a third party.
            </div>
            <div class="mt_20 pcss">
              (e) If there is a change of control in our business or a sale or
              transfer of business assets, we reserve the right to transfer to
              the extent permissible at law our user databases, together with
              any personal information and non-personal information contained in
              those databases. This information may be disclosed to a potential
              purchaser under an agreement to maintain confidentiality. We would
              seek to only disclose information in good faith and where required
              by any of the above circumstances.
            </div>
            <div class="mt_20 pcss">
              (f) By providing us with personal information, you consent to the
              terms of this Privacy Policy and the types of disclosure covered
              by this Policy. Where we disclose your personal information to
              third parties, we will request that the third party follow this
              Policy regarding handling your personal information.
            </div>
            <div class="mt_20 titlecss" style="font-weight: 700">
              6. Security of your personal information
            </div>
            <div class="mt_20 pcss">
              (a) Morris Group Australia Pty Ltd is committed to ensuring that
              the information you provide to us is secure. In order to prevent
              unauthorised access or disclosure, we have put in place suitable
              physical, electronic and managerial procedures to safeguard and
              secure information and protect it from misuse, interference, loss
              and unauthorised access, modification and disclosure.
            </div>
            <div class="mt_20 pcss">
              (b) The transmission and exchange of information is carried out at
              your own risk. We cannot guarantee the security of any information
              that you transmit to us, or receive from us. Although we take
              measures to safeguard against unauthorised disclosures of
              information, we cannot assure you that personal information that
              we collect will not be disclosed in a manner that is inconsistent
              with this Privacy Policy.
            </div>
            <div class="mt_20 titlecss" style="font-weight: 700">
              7. Access to your personal information
            </div>
            <div class="mt_20 pcss">
              (a) You may request details of personal information that we hold
              about you in accordance with the provisions of the Privacy Act
              1988 (Cth). A small administrative fee may be payable for the
              provision of information. If you would like a copy of the
              information, which we hold about you or believe that any
              information we hold on you is inaccurate, o of date, incomplete,
              irrelevant or misleading, please email us at  <a href="mailto:info@smartuser.com.au" style="color:rgb(9 82 124);" target="_blank">info@smartuser.com.au</a>
            </div>
            <div class="mt_20 pcss">
              (b) We reserve the right to refuse to provide you with information
              that we hold about you, in certain circumstances set out in the
              Privacy Act.
            </div>

             <div class="mt_20 titlecss" style="font-weight: 700">
             8. Complaints about privacy
            </div>
            <div class="mt_20 pcss">
             (a) If you have any complaints about our privacy practises, please feel free to send in details of your complaints to 11 Howleys Road , Notting Hill, Victoria, 3168. We take complaints very seriously and will respond shortly after receiving written notice of your complaint.
            </div>
             <div class="mt_20 titlecss" style="font-weight: 700">
             9. Changes to Privacy Policy
            </div>
            <div class="mt_20 pcss">
            (a) Please be aware that we may change this Privacy Policy in the future. We may modify this Policy at any time, in our sole discretion and all modifications will be effective immediately upon our posting of the modifications on our website or notice board. Please check back from time to time to review our Privacy Policy.
            </div>
             <div class="mt_20 titlecss" style="font-weight: 700">
             10. Website
            </div>
            <div class="mt_20 ">
           (a)<span style="font-weight:700">When you visit our website</span>
            </div>
             <div class="mt_20 pcss">
          When you come to our website <a href="https://smartuser.com.au/" style="color:rgb(9 82 124);" target="_blank">https://smartuser.com.au/</a> we may collect certain information such as browser type, operating system, website visited immediately before coming to our site, etc. This information is used in an aggregated manner to analyse how people use our site, such that we can improve our service.
            </div>
              <div class="mt_20">
           (b)<span style="font-weight:700">Cookies</span>
            </div>
             <div class="mt_20 pcss">
         We may from time to time use cookies on our website. Cookies are very small files which a website uses to identify you when you come back to the site and to store details about your use of the site. Cookies are not malicious programs that access or damage your computer. Most web browsers automatically accept cookies but you can choose to reject cookies by changing your browser settings. However, this may prevent you from taking full advantage of our website. Our website may from time to time use cookies to analyses website traffic and help us provide a better website visitor experience. In addition, cookies may be used to serve relevant ads to website visitors through third party services such as Google Adwords. These ads may appear on this website or other websites you visit.
            </div>

              <div class="mt_20">
           (c)<span style="font-weight:700">Third party sites</span>
            </div>
             <div class="mt_20 pcss">
        Our site may from time to time have links to other websites not owned or controlled by us. These links are meant for your convenience only. Links to third party websites do not constitute sponsorship or endorsement or approval of these websites. Please be aware that Morris Group Australia Pty Ltd is not responsible for the privacy practises of other such websites. We encourage our users to be aware, when they leave our website, to read the privacy statements of each and every website that collects personal identifiable information.
            </div>
              <div class="mt_20 " style="font-weight:700;font-size:15px">
                  For any questions and notices, please contact us at:
            </div>
             <div class="pcss" >
                  MORRIS GROUP AUSTRALIA Pty Ltd ACN 152 141 298 trading as Smart User
            </div>
             <div class="pcss" >
                 Email: 
                 <a href="mailto:info@smartuser.com.au" style="color:rgb(9 82 124);" target="_blank">info@smartuser.com.au</a>

            </div>
             <div  style="font-weight:700;font-size:15px">
                 Telephone: +61 03 8528 1831
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      offsetwidth: document.documentElement.clientWidth,
    };
  },
  mounted() {},

  methods: {
    // goinfo() {
    //   // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");
    //   this.$emit("fun", "/CustomerDetails");
    // },
  },
};
</script>

<style scoped>
.special-link {
  text-decoration: underline;
}
a:link {
  color: blue;
}
/* 已访问的链接 */
a:visited {
  color: purple;
}

/* 鼠标悬浮在链接上 */
a:hover {
  color: red;
}

.mt_20 {
  margin-top: 20px;
}
.mt_10 {
  margin-top: 10px;
}
.text_bold {
  font-weight: 700;
}
.pcss{
    /* font-family: Poppins; */
    font-weight: 300;
    /* font-style: normal; */
    font-size: 15px;
}
.titlecss{
    font-family: Poppins;
    font-style: normal;
}
</style>
