<template>
  <div v-title :data-title="$route.name">
    <!-- style=" border-style: solid; border-width: 5px; border-color: transparent;" -->
    <div ref="myElement" style="background:white;margin-top:65px">
      <div v-if="offsetwidth > 850">
        <div style="display: flex; justify-content: center">
          <div
            style="
              width: 76%;
              border: 1px solid #ccc;
              border-radius: 8px;
              padding: 10px;
            "
          >
            <div style="font-weight: 700; text-align: center; font-size: 32px">
              WEBSITE TERMS AND CONDITIONS OF USE
            </div>
            <div style="font-weight: 700; font-size: 17px;margin-top:50px">
              1.About the Website
            </div>
            <div class="mt_10">
              (a)Welcome to https://smartuser.com.au/ (or advertisement that led
              you to this page) (the 'Website'). The Website provides you with
              an opportunity to browse and purchase various products that have
              been listed for sale through the Website (the 'Products'). The
              Website provides this service by way of granting you access to the
              content on the Website (the 'Purchase Services').
            </div>
            <div class="mt_10">
              (b)The Website is operated by MORRIS GROUP AUSTRALIA Pty Ltd (ACN
              152 141 298). Access to and use of the Website, or any of its
              associated Products or Services, is provided by MORRIS GROUP
              AUSTRALIA Pty Ltd. Please read these terms and conditions (the
              'Terms') carefully. By using, browsing and/or reading the Website,
              this signifies that you have read, understood and agree to be
              bound by the Terms. If you do not agree with the Terms, you must
              cease usage of the Website, or any of Services, immediately.
            </div>
            <div class="mt_10">
              (c)MORRIS GROUP AUSTRALIA Pty Ltd reserves the right to review and
              change any of the Terms by updating this page at its sole
              discretion. When MORRIS GROUP AUSTRALIA Pty Ltd updates the Terms,
              it will use reasonable endeavours to provide you with notice of
              updates to the Terms. Any changes to the Terms take immediate
              effect from the date of their publication. Before you continue, we
              recommend you keep a copy of the Terms for your records.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              2.Acceptance of the Terms
            </div>
            <div class="mt_10">
              You accept the Terms by remaining on the Website. You may also
              accept the Terms by clicking to accept or agree to the Terms where
              this option is made available to you by MORRIS GROUP AUSTRALIA Pty
              Ltd in the user interface.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              3.Registration to use the Purchase Services
            </div>
            <div class="mt_10">
              (a)In order to access the Purchase Services, you must register and
              book a consultation (or register as a member, as the case may be).
              As part of the registration process, or as part of your continued
              use of the Purchase Services, you may be required to provide
              personal information about yourself (such as identification or
              contact details), including:
            </div>
            <div class="mt_10">(i)Email address</div>
            <div class="mt_10">(ii)Mailing and installation address</div>
            <div class="mt_10">(ii)Mailing and installation address</div>
            <div class="mt_10">(iii)Telephone number</div>
            <div class="mt_10">(iv)Name;</div>
            <div class="mt_10">(v)Build year of your property;</div>
            <div class="mt_10">
              (b)You warrant that any information you give to MORRIS GROUP
              AUSTRALIA Pty Ltd in the course of completing the registration
              process will always be accurate, correct and up to date.
            </div>
            <div class="mt_10">
              (c)Once you have completed the registration process, you will be a
              registered member ('Member') and agree to be bound by the Terms.
              As a Member you will be granted immediate access to the Purchase
              Services.
            </div>
            <div class="mt_10">
              (d)You may not use the Purchase Services and may not accept the
              Terms if:
            </div>
            <div class="mt_10">
              (a)you are not of legal age to form a binding contract with MORRIS
              GROUP AUSTRALIA Pty Ltd; or
            </div>
            <div class="mt_10">
              (b)you are a person barred from receiving the Purchase Services
              under the laws of Australia or other countries including the
              country in which you are resident or from which you use the
              Purchase Services (which include not being eligible under the
              Victorian Energy Upgrades Program).
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              4.Your obligations as a Member
            </div>
            <div class="mt_10">
              (a)As a Member, you agree to comply with the following: You will
              use the Purchase Services only for purposes that are permitted by:
            </div>
            <div class="mt_10">(i)the Terms;</div>
            <div class="mt_10">
              (ii)any applicable law, regulation or generally accepted practices
              or guidelines in the relevant jurisdictions;
            </div>
            <div class="mt_10">
              (iii)you have the sole responsibility for protecting the
              confidentiality of your password and/or email address. Use of your
              password, if any by any other person may result in the immediate
              cancellation of the Purchase Services;
            </div>
            <div class="mt_10">
              (iv)any use of your registration information by any other person,
              or third parties, is strictly prohibited. You agree to immediately
              notify MORRIS GROUP AUSTRALIA Pty Ltd of any unauthorised use of
              your password or email address or any breach of security of which
              you have become aware;
            </div>
            <div class="mt_10">
              (v)access and use of the Website is limited, non-transferable and
              allows for the sole use of the Website by you for the purposes of
              MORRIS GROUP AUSTRALIA Pty Ltd providing the Purchase Services;
            </div>
            <div class="mt_10">
              (vi)you will not use the Purchase Services or Website for any
              illegal and/or unauthorised use which includes collecting email
              addresses of Members by electronic or other means for the purpose
              of sending unsolicited email or unauthorised framing of or linking
              to the Website;
            </div>
            <div class="mt_10">
              (vii)you agree that commercial advertisements, affiliate links,
              and other forms of solicitation may be removed from the Website
              without notice and may result in termination of the Purchase
              Services. Appropriate legal action will be taken by MORRIS GROUP
              AUSTRALIA Pty Ltd for any illegal or unauthorised use of the
              Website; and
            </div>
            <div class="mt_10">
              (viii)you acknowledge and agree that any automated use of the
              Website or its Purchase Services is prohibited.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              5.Purchase of Products and Returns Policy
            </div>
            <div class="mt_10">
              (a)In using the Purchase Services to purchase the Product through
              the Website and after consultation, you will agree to the payment
              of the purchase price listed on the Website or within the
              consultation for the Product (the 'Purchase Price').
            </div>
            <div class="mt_10">
              (b)Payment of the Purchase Price may be made through Stripe,
              PayPal or other credit card, EFTPOS or VISA providers (the
              'Payment Gateway Provider'). In using the Purchase Services, you
              warrant that you have familiarised yourself with, and agree to be
              bound by, the applicable Terms and Conditions of Use, Privacy
              Policy and other relevant legal documentation provided by the
              Payment Gateway Providers.
            </div>
            <div class="mt_10">
              (c)Following payment of the Purchase Price being confirmed by
              MORRIS GROUP AUSTRALIA Pty Ltd, you will be issued with a receipt
              to confirm that the payment has been received and MORRIS GROUP
              AUSTRALIA Pty Ltd may record your purchase details for future use.
            </div>
            <div class="mt_10">
              (d)MORRIS GROUP AUSTRALIA Pty Ltd may, at their sole discretion,
              provide a refund on the return of the Products within 30 [#refund
              number of days#] days. You acknowledge and agree that you are
              liable for any postage, installation and shipping costs associated
              with any refund pursuant to this clause, if applicable.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              6.Warranty
            </div>
            <div class="mt_10">
              (a)MORRIS GROUP AUSTRALIA Pty Ltd's Products come with guarantees
              that cannot be excluded under the Australian Consumer Law. You are
              entitled to a replacement or refund for a major failure of the
              Product and compensation for any other reasonably foreseeable loss
              or damage. You are also entitled to have the Products repaired or
              replaced if the Products fail to be of acceptable quality and the
              failure does not amount to a major failure (the 'Warranty').
            </div>
            <div class="mt_10">
              (b)You may make a claim under this clause (the 'Warranty Claim')
              for material defects and workmanship in the Products within
              [#require your warranty period i.e. 30#] from the date of purchase
              (the 'Warranty Period').
            </div>
            <div class="mt_10">
              (c)In order to make a Warranty Claim during the Warranty Period,
              you must provide proof of purchase to MORRIS GROUP AUSTRALIA Pty
              Ltd showing the date of (c)purchase of the Products, provide a
              description of the Products and the price paid for the Products by
              sending written notice to MORRIS GROUP AUSTRALIA Pty Ltd at 11
              HOWLEYS ROAD, NOTTING HILL, Victoria, 3168 or by email at
              [#Service@smartuser.com.au#].
            </div>
            <div class="mt_10">
              (d)Where the Warranty Claim is accepted then MORRIS GROUP
              AUSTRALIA Pty Ltd will, at its sole discretion, either repair or
              replace any defective Products or part thereof with a new or
              remanufactured equivalent during the Warranty Period at no charge
              to you for parts or labour. You acknowledge and agree that you
              will be solely liable for any postage, installation or shipping
              costs incurred in facilitating the Warranty Claim.
            </div>
            <div class="mt_10">
              (e)The Warranty shall be the sole and exclusive warranty granted
              by MORRIS GROUP AUSTRALIA Pty Ltd and shall be the sole and
              exclusive remedy available to you in addition to other rights and
              under a law in relation to the Products to which this warranty
              relates.
            </div>
            <div class="mt_10">
              (f)All implied warranties including the warranties of
              merchantability and fitness for use are limited to the Warranty
              Period.
            </div>
            <div class="mt_10">
              (g)The Warranty does not apply to any appearance of the supplied
              Products nor to the additional excluded items set forth below nor
              to any supplied Products where the exterior of which has been
              damaged or defaced, which has been subjected to misuse, abnormal
              service or handling, or which has been altered or modified in
              design or construction.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              7.Delivery and Installers
            </div>
            <div class="mt_10">
              (a)You acknowledge that the Purchase Services offered by MORRIS
              GROUP AUSTRALIA Pty Ltd integrate delivery (the 'Delivery
              Services') and Installation (the ‘Installation Services’) through
              the use of third party delivery companies (the 'Delivery and
              Installation Service Providers').
            </div>
            <div class="mt_10">
              (b)In providing the Purchase Services, MORRIS GROUP AUSTRALIA Pty
              Ltd may provide you with a variety of delivery and insurance
              options offered as part of the Delivery Services and Installation
              Services by the Delivery and Installation Service Providers. You
              acknowledge and agree that MORRIS GROUP AUSTRALIA Pty Ltd is not
              the provider of these delivery and insurance options and merely
              facilitates your interaction with the Delivery and Installation
              Service Providers in respect to providing the Delivery Services
              and Installation Services.
            </div>
            <div class="mt_10">
              (c)In the event that an item is lost or damaged in the course of
              the Delivery Services or Installation Services, MORRIS GROUP
              AUSTRALIA Pty Ltd asks that you:
            </div>
            <div class="mt_10">
              (i)contact the Delivery and Installation Service Provider directly
              to request a refund or to claim on any insurance options
              available; and
            </div>
            <div class="mt_10">
              (ii)contact us by sending an email to [#info@smartuser.com.au#]
              outlining in what way the Products were damaged in transit so we
              are able to determine if the Delivery and Installation Service
              Provider should be removed from the Purchase Services.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              8.Copyright and Intellectual Property
            </div>
            <div class="mt_10">
              (a)The Website, the Purchase Services and all of the related
              products of MORRIS GROUP AUSTRALIA Pty Ltd are subject to
              copyright. The material on the Website is protected by copyright
              under the laws of Australia and through international treaties.
              Unless otherwise indicated, all rights (including copyright) in
              the site content and compilation of the website (including text,
              graphics, logos, button icons, video images, audio clips and
              software) (the 'Content') are owned or controlled for these
              purposes, and are reserved by MORRIS GROUP AUSTRALIA Pty Ltd or
              its contributors.
            </div>
            <div class="mt_10">
              (b)MORRIS GROUP AUSTRALIA Pty Ltd retains all rights, title and
              interest in and to the Website and all related content. Nothing
              you do on or in relation to the Website will transfer to you:
            </div>
            <div class="mt_10">
              (a)the business name, trading name, domain name, trade mark,
              industrial design, patent, registered design or copyright of
              MORRIS GROUP AUSTRALIA Pty Ltd; or
            </div>
            <div class="mt_10">
              (b)the right to use or exploit a business name, trading name,
              domain name, trade mark or industrial design; or
            </div>
            <div class="mt_10">
              (c)a system or process that is the subject of a patent, registered
              design or copyright (or an adaptation or modification of such a
              system or process).
            </div>
            <div class="mt_10">
              (c)You may not, without the prior written permission of MORRIS
              GROUP AUSTRALIA Pty Ltd and the permission of any other relevant
              rights owners: broadcast, republish, up-load to a third party,
              transmit, post, distribute, show or play in public, adapt or
              change in any way the Content or third party content for any
              purpose. This prohibition does not extend to materials on the
              Website, which are freely available for re-use or are in the
              public domain.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              9.Privacy
            </div>
            <div class="mt_10">
              MORRIS GROUP AUSTRALIA Pty Ltd takes your privacy seriously and
              any information provided through your use of the Website and/or
              the Purchase Services are subject to MORRIS GROUP AUSTRALIA Pty
              Ltd's Privacy Policy, which is available on the Website.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              10.General Disclaimer
            </div>
            <div class="mt_10">
              (a)You acknowledge that MORRIS GROUP AUSTRALIA Pty Ltd does not
              make any terms, guarantees, warranties, representations or
              conditions whatsoever regarding the Products other than provided
              for pursuant to these Terms.
            </div>
            <div class="mt_10">
              (b)MORRIS GROUP AUSTRALIA Pty Ltd will make every effort to ensure
              a Product is accurately depicted on the Website, however, you
              acknowledge that sizes, colours and packaging may differ from what
              is displayed on the Website.
            </div>
            <div class="mt_10">
              (c)Nothing in these Terms limits or excludes any guarantees,
              warranties, representations or conditions implied or imposed by
              law, including the Australian Consumer Law (or any liability under
              them) which by law may not be limited or excluded.
            </div>
            <div class="mt_10">
              (d)Subject to this clause, and to the extent permitted by law:
            </div>
            <div class="mt_10">
              (i)all terms, guarantees, warranties, representations or
              conditions which are not expressly stated in these Terms are
              excluded; and
            </div>
            <div class="mt_10">
              (ii)MORRIS GROUP AUSTRALIA Pty Ltd will not be liable for any
              special, indirect or consequential loss or damage (unless such
              loss or damage is reasonably foreseeable resulting from our
              failure to meet an applicable Consumer Guarantee), loss of profit
              or opportunity, or damage to goodwill arising out of or in
              connection with the Purchase Services or these Terms (including as
              a result of not being able to use the Purchase Services or the
              late supply of the Purchase Services), whether at common law,
              under contract, tort (including negligence), in equity, pursuant
              to statute or otherwise.
            </div>
            <div class="mt_10">
              (e)Use of the Website, the Purchase Services, and any of the
              products of MORRIS GROUP AUSTRALIA Pty Ltd (including the Delivery
              Services), is at your own risk. Everything on the Website, the
              Purchase Services, and the Products of MORRIS GROUP AUSTRALIA Pty
              Ltd, are provided to you on an "as is" and "as available" basis,
              without warranty or condition of any kind. None of the affiliates,
              directors, officers, employees, agents, contributors, third party
              content providers or licensors of MORRIS GROUP AUSTRALIA Pty
              Ltdincluding any third party where the Delivery Services are made
              available to you) make any express or implied representation or
              warranty about its Content or any products or Purchase Services
              (including the products or Purchase Services of MORRIS GROUP
              AUSTRALIA Pty Ltd) referred to on the Website. This includes (but
              is not restricted to) loss or damage you might suffer as a result
              of any of the following:
            </div>
            <div class="mt_10">
              (i)failure of performance, error, omission, interruption,
              deletion, defect, failure to correct defects, delay in operation
              or transmission, computer virus or other harmful component, loss
              of data, communication line failure, unlawful third party conduct,
              or theft, destruction, alteration or unauthorised access to
              records;
            </div>
            <div class="mt_10">
              (ii)the accuracy, suitability or currency of any information on
              the Website, the Purchase Service, or any of its Content related
              products (including third party material and advertisements on the
              Website);
            </div>
            <div class="mt_10">
              (iii)costs incurred as a result of you using the Website, the
              Purchase Services or any of the Products;
            </div>
            <div class="mt_10">
              (iv)the Content or operation in respect to links which are
              provided for the User's convenience;
            </div>
            <div class="mt_10">
              (v)any failure to complete a transaction, or any loss arising from
              e-commerce transacted on the Website; or
            </div>
            <div class="mt_10">
              (vi)any defamatory, threatening, offensive or unlawful conduct of
              third parties or publication of any materials relating to or
              constituting such conduct.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              11.Limitation of Liability
            </div>
            <div class="mt_10">
              (a)To the maximum extent permitted by law, MORRIS GROUP AUSTRALIA
              Pty Ltd's total liability arising out of or in connection with the
              Purchase Services or these Terms, however arising, including under
              contract, tort (including negligence), in equity, under statute or
              otherwise, will not exceed the most recent Purchase Price paid by
              you under these Terms or where you have not paid the Purchase
              Price, then the total liability of MORRIS GROUP AUSTRALIA Pty Ltd
              is the resupply of information or Purchase Services to you.
            </div>
            <div class="mt_10">
              (b)You expressly understand and agree that MORRIS GROUP AUSTRALIA
              Pty Ltd, its affiliates, employees, agents, contributors, third
              party content providers and licensors shall not be liable to you
              for any direct, indirect, incidental, special consequential or
              exemplary damages which may be incurred by you, however caused and
              under any theory of liability. This shall include, but is not
              limited to, any loss of profit (whether incurred directly or
              indirectly), any loss of goodwill or business reputation and any
              other intangible loss.
            </div>
            <div class="mt_10">
              (c)To the maximum extent permitted by law, MORRIS GROUP AUSTRALIA
              Pty Ltd is not responsible or liable in any manner for any site
              content (including the Content and Third Party Content) posted on
              the Website or in connection with the Purchase Services, whether
              posted or caused by users of the website of MORRIS GROUP AUSTRALIA
              Pty Ltd, by third parties or by any of the Purchase Services
              offered by MORRIS GROUP AUSTRALIA Pty Ltd.
            </div>
            <div class="mt_10">
              (d)To the maximum extent permitted by law, we are not responsible
              for any loss, damage or expense, howsoever arising, whether direct
              or indirect and/or whether present, unascertained, future or
              contingent (Liability) suffered by you or any third party, arising
              from or in connection with your use of our Site and/or the Content
              and/or any inaccessibility of, interruption to or outage of our
              Site and/or (d)any loss or corruption of data and/or the fact that
              the Content is incorrect, incomplete or out-of-date.
            </div>
            <div class="mt_10">
              (e)You acknowledge that MORRIS GROUP AUSTRALIA Pty Ltd does not
              provide the Delivery Services to you and you agree that MORRIS
              GROUP AUSTRALIA Pty Ltd will not be liable to you for any special,
              indirect or consequential loss or damage, loss of profit or
              opportunity, or damage to goodwill arising out of or in connection
              with the Delivery Services.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              12.Termination of Contract
            </div>
            <div class="mt_10">
              (a)The Terms will continue to apply until terminated by either you
              or by MORRIS GROUP AUSTRALIA Pty Ltd as set out below.
            </div>
            <div class="mt_10">
              (b)If you want to terminate the Terms, you may do so by:
            </div>
            <div class="mt_10">
              (i)notifying MORRIS GROUP AUSTRALIA Pty Ltd at any time; and
            </div>
            <div class="mt_10">
              (ii)closing your accounts for all of the Purchase Services which
              you use, where MORRIS GROUP AUSTRALIA Pty Ltd has made this option
              available to you.
            </div>
            <div class="mt_10">
              (c)Your notice should be sent, in writing, to MORRIS GROUP
              AUSTRALIA Pty Ltd via the 'Contact Us' link on our homepage.
            </div>
            <div class="mt_10">
              (d)MORRIS GROUP AUSTRALIA Pty Ltd may at any time, terminate the
              Terms with you if:
            </div>
            <div class="mt_10">
              (i)you have breached any provision of the Terms or intend to
              breach any provision;
            </div>
            <div class="mt_10">
              (ii)MORRIS GROUP AUSTRALIA Pty Ltd is required to do so by law;
            </div>
            <div class="mt_10">
              (iii)the partner with whom MORRIS GROUP AUSTRALIA Pty Ltd offered
              the Purchase Services to you has terminated its relationship with
              MORRIS GROUP AUSTRALIA Pty Ltd or ceased to offer the Purchase
              Services to you;
            </div>
            <div class="mt_10">
              (iv)MORRIS GROUP AUSTRALIA Pty Ltd is transitioning to no longer
              providing the Purchase Services to Users in the country in which
              you are resident or from which you use the service; or
            </div>
            <div class="mt_10">
              (v)the provision of the Purchase Services to you by MORRIS GROUP
              AUSTRALIA Pty Ltd is, in the opinion of MORRIS GROUP AUSTRALIA Pty
              Ltd, no longer commercially viable.
            </div>
            <div class="mt_10">
              (e)Subject to local applicable laws, MORRIS GROUP AUSTRALIA Pty
              Ltd reserves the right to discontinue or cancel your membership to
              the Website at any time and may suspend or deny, in its sole
              discretion, your access to all or any portion of the Website or
              the Purchase Services without notice if you breach any provision
              of the (e)Terms or any applicable law or if your conduct impacts
              MORRIS GROUP AUSTRALIA Pty Ltd's name or reputation or violates
              the rights of those of another party.
            </div>
            <div class="mt_10">
              (f)When the Terms come to an end, all of the legal rights,
              obligations and liabilities that you and MORRIS GROUP AUSTRALIA
              Pty Ltd have benefited from, been subject to (or which have
              accrued over time whilst the Terms have been in force) or which
              are expressed to continue indefinitely, shall be unaffected by
              this cessation, and the provisions of this clause shall continue
              to apply to such rights, obligations and liabilities indefinitely.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              13.Indemnity
            </div>
            <div class="mt_10">
              (a)You agree to indemnify MORRIS GROUP AUSTRALIA Pty Ltd, its
              affiliates, employees, agents, contributors, third party content
              providers and licensors from and against:
            </div>
            <div class="mt_10">
              (i)all actions, suits, claims, demands, liabilities, costs,
              expenses, loss and damage (including legal fees on a full
              indemnity basis) incurred, suffered or arising out of or in
              connection with any Content you post through the Website;
            </div>
            <div class="mt_10">
              (ii)any direct or indirect consequences of you accessing, using or
              transacting on the Website or attempts to do so and any breach by
              you or your agents of these Terms; and/or
            </div>
            <div class="mt_10">(iii)any breach of the Terms.</div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              14.Dispute Resolution
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              14.1. Compulsory
            </div>
            <div class="mt_10">
              If a dispute arises out of or relates to the Terms, either party
              may not commence any Tribunal or Court proceedings in relation to
              the dispute, unless the following clauses have been complied with
              (except where urgent interlocutory relief is sought).
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              14.2. Notice
            </div>
            <div class="mt_10">
              A party to the Terms claiming a dispute ('Dispute') has arisen
              under the Terms, must give written notice to the other party
              detailing the nature of the dispute, the desired outcome and the
              action required to settle the Dispute.
            </div>
            <div style="font-weight: 700; font-size: 17px">
              14.3. Resolution
            </div>
            <div class="mt_10">
              On receipt of that notice ('Notice') by that other party, the
              parties to the Terms ('Parties') must:
            </div>
            <div class="mt_10">
              (i)Within 21 days of the Notice endeavour in good faith to resolve
              the Dispute expeditiously by negotiation or such other means upon
              which they may mutually agree;
            </div>
            <div class="mt_10">
              (ii)If for any reason whatsoever, 21 days after the date of the
              Notice, the Dispute has not been resolved, the Parties must either
              agree upon selection of a mediator or request that an appropriate
              mediator be appointed by the President of the Law Institute of
              Victoria or his or her nominee;
            </div>
            <div class="mt_10">
              (iii)The Parties are equally liable for the fees and reasonable
              expenses of a mediator and the cost of the venue of the mediation
              and without limiting the foregoing undertake to pay any amounts
              requested by the mediator as a pre-condition to the mediation
              commencing. The Parties must each pay their own costs associated
              with the mediation;
            </div>
            <div class="mt_10">
              (iv)The mediation will be held in Melbourne, Australia.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              14.4. Confidential
            </div>
            <div class="mt_10">
              All communications concerning negotiations made by the Parties
              arising out of and in connection with this dispute resolution
              clause are confidential and to the extent possible, must be
              treated as "without prejudice" negotiations for the purpose of
              applicable laws of evidence.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              14.5. Termination of Mediation
            </div>
            <div class="mt_10">
              If 30 days have elapsed after the start of a mediation of the
              Dispute and the Dispute has not been resolved, either Party may
              ask the mediator to terminate the mediation and the mediator must
              do so.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              15.Use of Cookies
            </div>
            <div class="mt_10">
              (a)Cookies are small pieces of information that are stored by your
              browser on your computer’s hard drive. A cookie cannot read or
              collect personal identifiable information or any other data from
              your hard drive and takes up very little memory. MORRIS GROUP
              AUSTRALIA Pty Ltd may use cookies on its Sites, including its
              service providers, related companies and third parties such as
              analytics, advertising or ad serving partners. The information
              collected is aggregated from all MORRIS GROUP AUSTRALIA Pty Ltd’s
              customers to improve its Site by identifying how different areas
              of the site are viewed, used or utilised.
            </div>
            <div class="mt_10">
              You may choose to disable cookies on your web browser. However, by
              doing so, MORRIS GROUP AUSTRALIA Pty Ltd may not be able to
              provide you all features that is available to you on the Site.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              16.Venue and Jurisdiction
            </div>
            <div class="mt_10">
              The Purchase Services offered by MORRIS GROUP AUSTRALIA Pty Ltd
              are intended to be used by residents of Australia. In the event of
              any dispute arising out of or in relation to the Website, you
              agree that the exclusive venue for resolving any dispute shall be
              in the courts of Victoria, Australia.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              17.Governing Law
            </div>
            <div class="mt_10">
              The Terms are governed by the laws of Victoria, Australia. Any
              dispute, controversy, proceeding or claim of whatever nature
              arising out of or in any way relating to the Terms and the rights
              created hereby shall be governed, interpreted and construed by,
              under and pursuant to the laws of Victoria Australia, without
              reference to conflict of law principles, notwithstanding mandatory
              rules. The validity of this governing law clause is not contested.
              The Terms shall be binding to the benefit of the parties hereto
              and their successors and assigns.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              18.Independent Legal Advice
            </div>
            <div class="mt_10">
              Both parties confirm and declare that the provisions of the Terms
              are fair and reasonable and both parties having taken the
              opportunity to obtain independent legal advice and declare the
              Terms are not against public policy on the grounds of inequality
              or bargaining power or general grounds of restraint of trade.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              19.Severance
            </div>
            <div class="mt_10">
              If any part of these Terms is found to be void or unenforceable by
              a Court of competent jurisdiction, that part shall be severed and
              the rest of the Terms shall remain in force.
            </div>
            <div class="mt_10">
              For any questions and notices, please contact us at:
            </div>
            <div class="mt_10">
              MORRIS GROUP AUSTRALIA Pty Ltd (ACN 152 141 298) T/A Smart User,
              Mega Energy and Astep to Green ABC 12 152 141 298
            </div>
            <div class="mt_10" style="font-weight: 700">
              Email: info@smartuser.com.au
            </div>
            <div class="mt_10" style="font-weight: 700">
              Last update: 10 July 2023
            </div>

            <!-- <div class="header">
          <div class="top_logo">
            <img src="./img/mini_toplogo.jpg" style="height: 30px">
          </div>
        </div> -->
            <!-- <div class="home_form_item radius_top" style=" line-height: 30px;">
          <div>
            <div style="font-weight: 600; font-size: 17px; padding: 10px 20px">
              <span>Customer Information</span>
            </div>
          </div>
        </div>   -->
          </div>
        </div>
        <!-- <img style="width: 100%" src="../assets/imgthree/PC/pp.jpg" alt="" /> -->
      </div>

      <div v-else style="margin-bottom: 20px">
        <div style="display: flex; justify-content: center">
            <div
            style="
              width: 76%;
              border: 1px solid #ccc;
              border-radius: 8px;
              padding: 10px;
            "
          >
            <h1 style="font-weight: 700; text-align: center;font-size:32px">
              WEBSITE TERMS AND CONDITIONS OF USE
            </h1>
            <div style="font-weight: 700; font-size: 17px">
              1.About the Website
            </div>
            <div class="mt_10">
              (a)Welcome to https://smartuser.com.au/ (or advertisement that led
              you to this page) (the 'Website'). The Website provides you with
              an opportunity to browse and purchase various products that have
              been listed for sale through the Website (the 'Products'). The
              Website provides this service by way of granting you access to the
              content on the Website (the 'Purchase Services').
            </div>
            <div class="mt_10">
              (b)The Website is operated by MORRIS GROUP AUSTRALIA Pty Ltd (ACN
              152 141 298). Access to and use of the Website, or any of its
              associated Products or Services, is provided by MORRIS GROUP
              AUSTRALIA Pty Ltd. Please read these terms and conditions (the
              'Terms') carefully. By using, browsing and/or reading the Website,
              this signifies that you have read, understood and agree to be
              bound by the Terms. If you do not agree with the Terms, you must
              cease usage of the Website, or any of Services, immediately.
            </div>
            <div class="mt_10">
              (c)MORRIS GROUP AUSTRALIA Pty Ltd reserves the right to review and
              change any of the Terms by updating this page at its sole
              discretion. When MORRIS GROUP AUSTRALIA Pty Ltd updates the Terms,
              it will use reasonable endeavours to provide you with notice of
              updates to the Terms. Any changes to the Terms take immediate
              effect from the date of their publication. Before you continue, we
              recommend you keep a copy of the Terms for your records.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              2.Acceptance of the Terms
            </div>
            <div class="mt_10">
              You accept the Terms by remaining on the Website. You may also
              accept the Terms by clicking to accept or agree to the Terms where
              this option is made available to you by MORRIS GROUP AUSTRALIA Pty
              Ltd in the user interface.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              3.Registration to use the Purchase Services
            </div>
            <div class="mt_10">
              (a)In order to access the Purchase Services, you must register and
              book a consultation (or register as a member, as the case may be).
              As part of the registration process, or as part of your continued
              use of the Purchase Services, you may be required to provide
              personal information about yourself (such as identification or
              contact details), including:
            </div>
            <div class="mt_10">(i)Email address</div>
            <div class="mt_10">(ii)Mailing and installation address</div>
            <div class="mt_10">(ii)Mailing and installation address</div>
            <div class="mt_10">(iii)Telephone number</div>
            <div class="mt_10">(iv)Name;</div>
            <div class="mt_10">(v)Build year of your property;</div>
            <div class="mt_10">
              (b)You warrant that any information you give to MORRIS GROUP
              AUSTRALIA Pty Ltd in the course of completing the registration
              process will always be accurate, correct and up to date.
            </div>
            <div class="mt_10">
              (c)Once you have completed the registration process, you will be a
              registered member ('Member') and agree to be bound by the Terms.
              As a Member you will be granted immediate access to the Purchase
              Services.
            </div>
            <div class="mt_10">
              (d)You may not use the Purchase Services and may not accept the
              Terms if:
            </div>
            <div class="mt_10">
              (a)you are not of legal age to form a binding contract with MORRIS
              GROUP AUSTRALIA Pty Ltd; or
            </div>
            <div class="mt_10">
              (b)you are a person barred from receiving the Purchase Services
              under the laws of Australia or other countries including the
              country in which you are resident or from which you use the
              Purchase Services (which include not being eligible under the
              Victorian Energy Upgrades Program).
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              4.Your obligations as a Member
            </div>
            <div class="mt_10">
              (a)As a Member, you agree to comply with the following: You will
              use the Purchase Services only for purposes that are permitted by:
            </div>
            <div class="mt_10">(i)the Terms;</div>
            <div class="mt_10">
              (ii)any applicable law, regulation or generally accepted practices
              or guidelines in the relevant jurisdictions;
            </div>
            <div class="mt_10">
              (iii)you have the sole responsibility for protecting the
              confidentiality of your password and/or email address. Use of your
              password, if any by any other person may result in the immediate
              cancellation of the Purchase Services;
            </div>
            <div class="mt_10">
              (iv)any use of your registration information by any other person,
              or third parties, is strictly prohibited. You agree to immediately
              notify MORRIS GROUP AUSTRALIA Pty Ltd of any unauthorised use of
              your password or email address or any breach of security of which
              you have become aware;
            </div>
            <div class="mt_10">
              (v)access and use of the Website is limited, non-transferable and
              allows for the sole use of the Website by you for the purposes of
              MORRIS GROUP AUSTRALIA Pty Ltd providing the Purchase Services;
            </div>
            <div class="mt_10">
              (vi)you will not use the Purchase Services or Website for any
              illegal and/or unauthorised use which includes collecting email
              addresses of Members by electronic or other means for the purpose
              of sending unsolicited email or unauthorised framing of or linking
              to the Website;
            </div>
            <div class="mt_10">
              (vii)you agree that commercial advertisements, affiliate links,
              and other forms of solicitation may be removed from the Website
              without notice and may result in termination of the Purchase
              Services. Appropriate legal action will be taken by MORRIS GROUP
              AUSTRALIA Pty Ltd for any illegal or unauthorised use of the
              Website; and
            </div>
            <div class="mt_10">
              (viii)you acknowledge and agree that any automated use of the
              Website or its Purchase Services is prohibited.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              5.Purchase of Products and Returns Policy
            </div>
            <div class="mt_10">
              (a)In using the Purchase Services to purchase the Product through
              the Website and after consultation, you will agree to the payment
              of the purchase price listed on the Website or within the
              consultation for the Product (the 'Purchase Price').
            </div>
            <div class="mt_10">
              (b)Payment of the Purchase Price may be made through Stripe,
              PayPal or other credit card, EFTPOS or VISA providers (the
              'Payment Gateway Provider'). In using the Purchase Services, you
              warrant that you have familiarised yourself with, and agree to be
              bound by, the applicable Terms and Conditions of Use, Privacy
              Policy and other relevant legal documentation provided by the
              Payment Gateway Providers.
            </div>
            <div class="mt_10">
              (c)Following payment of the Purchase Price being confirmed by
              MORRIS GROUP AUSTRALIA Pty Ltd, you will be issued with a receipt
              to confirm that the payment has been received and MORRIS GROUP
              AUSTRALIA Pty Ltd may record your purchase details for future use.
            </div>
            <div class="mt_10">
              (d)MORRIS GROUP AUSTRALIA Pty Ltd may, at their sole discretion,
              provide a refund on the return of the Products within 30 [#refund
              number of days#] days. You acknowledge and agree that you are
              liable for any postage, installation and shipping costs associated
              with any refund pursuant to this clause, if applicable.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              6.Warranty
            </div>
            <div class="mt_10">
              (a)MORRIS GROUP AUSTRALIA Pty Ltd's Products come with guarantees
              that cannot be excluded under the Australian Consumer Law. You are
              entitled to a replacement or refund for a major failure of the
              Product and compensation for any other reasonably foreseeable loss
              or damage. You are also entitled to have the Products repaired or
              replaced if the Products fail to be of acceptable quality and the
              failure does not amount to a major failure (the 'Warranty').
            </div>
            <div class="mt_10">
              (b)You may make a claim under this clause (the 'Warranty Claim')
              for material defects and workmanship in the Products within
              [#require your warranty period i.e. 30#] from the date of purchase
              (the 'Warranty Period').
            </div>
            <div class="mt_10">
              (c)In order to make a Warranty Claim during the Warranty Period,
              you must provide proof of purchase to MORRIS GROUP AUSTRALIA Pty
              Ltd showing the date of (c)purchase of the Products, provide a
              description of the Products and the price paid for the Products by
              sending written notice to MORRIS GROUP AUSTRALIA Pty Ltd at 11
              HOWLEYS ROAD, NOTTING HILL, Victoria, 3168 or by email at
              [#Service@smartuser.com.au#].
            </div>
            <div class="mt_10">
              (d)Where the Warranty Claim is accepted then MORRIS GROUP
              AUSTRALIA Pty Ltd will, at its sole discretion, either repair or
              replace any defective Products or part thereof with a new or
              remanufactured equivalent during the Warranty Period at no charge
              to you for parts or labour. You acknowledge and agree that you
              will be solely liable for any postage, installation or shipping
              costs incurred in facilitating the Warranty Claim.
            </div>
            <div class="mt_10">
              (e)The Warranty shall be the sole and exclusive warranty granted
              by MORRIS GROUP AUSTRALIA Pty Ltd and shall be the sole and
              exclusive remedy available to you in addition to other rights and
              under a law in relation to the Products to which this warranty
              relates.
            </div>
            <div class="mt_10">
              (f)All implied warranties including the warranties of
              merchantability and fitness for use are limited to the Warranty
              Period.
            </div>
            <div class="mt_10">
              (g)The Warranty does not apply to any appearance of the supplied
              Products nor to the additional excluded items set forth below nor
              to any supplied Products where the exterior of which has been
              damaged or defaced, which has been subjected to misuse, abnormal
              service or handling, or which has been altered or modified in
              design or construction.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              7.Delivery and Installers
            </div>
            <div class="mt_10">
              (a)You acknowledge that the Purchase Services offered by MORRIS
              GROUP AUSTRALIA Pty Ltd integrate delivery (the 'Delivery
              Services') and Installation (the ‘Installation Services’) through
              the use of third party delivery companies (the 'Delivery and
              Installation Service Providers').
            </div>
            <div class="mt_10">
              (b)In providing the Purchase Services, MORRIS GROUP AUSTRALIA Pty
              Ltd may provide you with a variety of delivery and insurance
              options offered as part of the Delivery Services and Installation
              Services by the Delivery and Installation Service Providers. You
              acknowledge and agree that MORRIS GROUP AUSTRALIA Pty Ltd is not
              the provider of these delivery and insurance options and merely
              facilitates your interaction with the Delivery and Installation
              Service Providers in respect to providing the Delivery Services
              and Installation Services.
            </div>
            <div class="mt_10">
              (c)In the event that an item is lost or damaged in the course of
              the Delivery Services or Installation Services, MORRIS GROUP
              AUSTRALIA Pty Ltd asks that you:
            </div>
            <div class="mt_10">
              (i)contact the Delivery and Installation Service Provider directly
              to request a refund or to claim on any insurance options
              available; and
            </div>
            <div class="mt_10">
              (ii)contact us by sending an email to [#info@smartuser.com.au#]
              outlining in what way the Products were damaged in transit so we
              are able to determine if the Delivery and Installation Service
              Provider should be removed from the Purchase Services.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              8.Copyright and Intellectual Property
            </div>
            <div class="mt_10">
              (a)The Website, the Purchase Services and all of the related
              products of MORRIS GROUP AUSTRALIA Pty Ltd are subject to
              copyright. The material on the Website is protected by copyright
              under the laws of Australia and through international treaties.
              Unless otherwise indicated, all rights (including copyright) in
              the site content and compilation of the website (including text,
              graphics, logos, button icons, video images, audio clips and
              software) (the 'Content') are owned or controlled for these
              purposes, and are reserved by MORRIS GROUP AUSTRALIA Pty Ltd or
              its contributors.
            </div>
            <div class="mt_10">
              (b)MORRIS GROUP AUSTRALIA Pty Ltd retains all rights, title and
              interest in and to the Website and all related content. Nothing
              you do on or in relation to the Website will transfer to you:
            </div>
            <div class="mt_10">
              (a)the business name, trading name, domain name, trade mark,
              industrial design, patent, registered design or copyright of
              MORRIS GROUP AUSTRALIA Pty Ltd; or
            </div>
            <div class="mt_10">
              (b)the right to use or exploit a business name, trading name,
              domain name, trade mark or industrial design; or
            </div>
            <div class="mt_10">
              (c)a system or process that is the subject of a patent, registered
              design or copyright (or an adaptation or modification of such a
              system or process).
            </div>
            <div class="mt_10">
              (c)You may not, without the prior written permission of MORRIS
              GROUP AUSTRALIA Pty Ltd and the permission of any other relevant
              rights owners: broadcast, republish, up-load to a third party,
              transmit, post, distribute, show or play in public, adapt or
              change in any way the Content or third party content for any
              purpose. This prohibition does not extend to materials on the
              Website, which are freely available for re-use or are in the
              public domain.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              9.Privacy
            </div>
            <div class="mt_10">
              MORRIS GROUP AUSTRALIA Pty Ltd takes your privacy seriously and
              any information provided through your use of the Website and/or
              the Purchase Services are subject to MORRIS GROUP AUSTRALIA Pty
              Ltd's Privacy Policy, which is available on the Website.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              10.General Disclaimer
            </div>
            <div class="mt_10">
              (a)You acknowledge that MORRIS GROUP AUSTRALIA Pty Ltd does not
              make any terms, guarantees, warranties, representations or
              conditions whatsoever regarding the Products other than provided
              for pursuant to these Terms.
            </div>
            <div class="mt_10">
              (b)MORRIS GROUP AUSTRALIA Pty Ltd will make every effort to ensure
              a Product is accurately depicted on the Website, however, you
              acknowledge that sizes, colours and packaging may differ from what
              is displayed on the Website.
            </div>
            <div class="mt_10">
              (c)Nothing in these Terms limits or excludes any guarantees,
              warranties, representations or conditions implied or imposed by
              law, including the Australian Consumer Law (or any liability under
              them) which by law may not be limited or excluded.
            </div>
            <div class="mt_10">
              (d)Subject to this clause, and to the extent permitted by law:
            </div>
            <div class="mt_10">
              (i)all terms, guarantees, warranties, representations or
              conditions which are not expressly stated in these Terms are
              excluded; and
            </div>
            <div class="mt_10">
              (ii)MORRIS GROUP AUSTRALIA Pty Ltd will not be liable for any
              special, indirect or consequential loss or damage (unless such
              loss or damage is reasonably foreseeable resulting from our
              failure to meet an applicable Consumer Guarantee), loss of profit
              or opportunity, or damage to goodwill arising out of or in
              connection with the Purchase Services or these Terms (including as
              a result of not being able to use the Purchase Services or the
              late supply of the Purchase Services), whether at common law,
              under contract, tort (including negligence), in equity, pursuant
              to statute or otherwise.
            </div>
            <div class="mt_10">
              (e)Use of the Website, the Purchase Services, and any of the
              products of MORRIS GROUP AUSTRALIA Pty Ltd (including the Delivery
              Services), is at your own risk. Everything on the Website, the
              Purchase Services, and the Products of MORRIS GROUP AUSTRALIA Pty
              Ltd, are provided to you on an "as is" and "as available" basis,
              without warranty or condition of any kind. None of the affiliates,
              directors, officers, employees, agents, contributors, third party
              content providers or licensors of MORRIS GROUP AUSTRALIA Pty
              Ltdincluding any third party where the Delivery Services are made
              available to you) make any express or implied representation or
              warranty about its Content or any products or Purchase Services
              (including the products or Purchase Services of MORRIS GROUP
              AUSTRALIA Pty Ltd) referred to on the Website. This includes (but
              is not restricted to) loss or damage you might suffer as a result
              of any of the following:
            </div>
            <div class="mt_10">
              (i)failure of performance, error, omission, interruption,
              deletion, defect, failure to correct defects, delay in operation
              or transmission, computer virus or other harmful component, loss
              of data, communication line failure, unlawful third party conduct,
              or theft, destruction, alteration or unauthorised access to
              records;
            </div>
            <div class="mt_10">
              (ii)the accuracy, suitability or currency of any information on
              the Website, the Purchase Service, or any of its Content related
              products (including third party material and advertisements on the
              Website);
            </div>
            <div class="mt_10">
              (iii)costs incurred as a result of you using the Website, the
              Purchase Services or any of the Products;
            </div>
            <div class="mt_10">
              (iv)the Content or operation in respect to links which are
              provided for the User's convenience;
            </div>
            <div class="mt_10">
              (v)any failure to complete a transaction, or any loss arising from
              e-commerce transacted on the Website; or
            </div>
            <div class="mt_10">
              (vi)any defamatory, threatening, offensive or unlawful conduct of
              third parties or publication of any materials relating to or
              constituting such conduct.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              11.Limitation of Liability
            </div>
            <div class="mt_10">
              (a)To the maximum extent permitted by law, MORRIS GROUP AUSTRALIA
              Pty Ltd's total liability arising out of or in connection with the
              Purchase Services or these Terms, however arising, including under
              contract, tort (including negligence), in equity, under statute or
              otherwise, will not exceed the most recent Purchase Price paid by
              you under these Terms or where you have not paid the Purchase
              Price, then the total liability of MORRIS GROUP AUSTRALIA Pty Ltd
              is the resupply of information or Purchase Services to you.
            </div>
            <div class="mt_10">
              (b)You expressly understand and agree that MORRIS GROUP AUSTRALIA
              Pty Ltd, its affiliates, employees, agents, contributors, third
              party content providers and licensors shall not be liable to you
              for any direct, indirect, incidental, special consequential or
              exemplary damages which may be incurred by you, however caused and
              under any theory of liability. This shall include, but is not
              limited to, any loss of profit (whether incurred directly or
              indirectly), any loss of goodwill or business reputation and any
              other intangible loss.
            </div>
            <div class="mt_10">
              (c)To the maximum extent permitted by law, MORRIS GROUP AUSTRALIA
              Pty Ltd is not responsible or liable in any manner for any site
              content (including the Content and Third Party Content) posted on
              the Website or in connection with the Purchase Services, whether
              posted or caused by users of the website of MORRIS GROUP AUSTRALIA
              Pty Ltd, by third parties or by any of the Purchase Services
              offered by MORRIS GROUP AUSTRALIA Pty Ltd.
            </div>
            <div class="mt_10">
              (d)To the maximum extent permitted by law, we are not responsible
              for any loss, damage or expense, howsoever arising, whether direct
              or indirect and/or whether present, unascertained, future or
              contingent (Liability) suffered by you or any third party, arising
              from or in connection with your use of our Site and/or the Content
              and/or any inaccessibility of, interruption to or outage of our
              Site and/or (d)any loss or corruption of data and/or the fact that
              the Content is incorrect, incomplete or out-of-date.
            </div>
            <div class="mt_10">
              (e)You acknowledge that MORRIS GROUP AUSTRALIA Pty Ltd does not
              provide the Delivery Services to you and you agree that MORRIS
              GROUP AUSTRALIA Pty Ltd will not be liable to you for any special,
              indirect or consequential loss or damage, loss of profit or
              opportunity, or damage to goodwill arising out of or in connection
              with the Delivery Services.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              12.Termination of Contract
            </div>
            <div class="mt_10">
              (a)The Terms will continue to apply until terminated by either you
              or by MORRIS GROUP AUSTRALIA Pty Ltd as set out below.
            </div>
            <div class="mt_10">
              (b)If you want to terminate the Terms, you may do so by:
            </div>
            <div class="mt_10">
              (i)notifying MORRIS GROUP AUSTRALIA Pty Ltd at any time; and
            </div>
            <div class="mt_10">
              (ii)closing your accounts for all of the Purchase Services which
              you use, where MORRIS GROUP AUSTRALIA Pty Ltd has made this option
              available to you.
            </div>
            <div class="mt_10">
              (c)Your notice should be sent, in writing, to MORRIS GROUP
              AUSTRALIA Pty Ltd via the 'Contact Us' link on our homepage.
            </div>
            <div class="mt_10">
              (d)MORRIS GROUP AUSTRALIA Pty Ltd may at any time, terminate the
              Terms with you if:
            </div>
            <div class="mt_10">
              (i)you have breached any provision of the Terms or intend to
              breach any provision;
            </div>
            <div class="mt_10">
              (ii)MORRIS GROUP AUSTRALIA Pty Ltd is required to do so by law;
            </div>
            <div class="mt_10">
              (iii)the partner with whom MORRIS GROUP AUSTRALIA Pty Ltd offered
              the Purchase Services to you has terminated its relationship with
              MORRIS GROUP AUSTRALIA Pty Ltd or ceased to offer the Purchase
              Services to you;
            </div>
            <div class="mt_10">
              (iv)MORRIS GROUP AUSTRALIA Pty Ltd is transitioning to no longer
              providing the Purchase Services to Users in the country in which
              you are resident or from which you use the service; or
            </div>
            <div class="mt_10">
              (v)the provision of the Purchase Services to you by MORRIS GROUP
              AUSTRALIA Pty Ltd is, in the opinion of MORRIS GROUP AUSTRALIA Pty
              Ltd, no longer commercially viable.
            </div>
            <div class="mt_10">
              (e)Subject to local applicable laws, MORRIS GROUP AUSTRALIA Pty
              Ltd reserves the right to discontinue or cancel your membership to
              the Website at any time and may suspend or deny, in its sole
              discretion, your access to all or any portion of the Website or
              the Purchase Services without notice if you breach any provision
              of the (e)Terms or any applicable law or if your conduct impacts
              MORRIS GROUP AUSTRALIA Pty Ltd's name or reputation or violates
              the rights of those of another party.
            </div>
            <div class="mt_10">
              (f)When the Terms come to an end, all of the legal rights,
              obligations and liabilities that you and MORRIS GROUP AUSTRALIA
              Pty Ltd have benefited from, been subject to (or which have
              accrued over time whilst the Terms have been in force) or which
              are expressed to continue indefinitely, shall be unaffected by
              this cessation, and the provisions of this clause shall continue
              to apply to such rights, obligations and liabilities indefinitely.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              13.Indemnity
            </div>
            <div class="mt_10">
              (a)You agree to indemnify MORRIS GROUP AUSTRALIA Pty Ltd, its
              affiliates, employees, agents, contributors, third party content
              providers and licensors from and against:
            </div>
            <div class="mt_10">
              (i)all actions, suits, claims, demands, liabilities, costs,
              expenses, loss and damage (including legal fees on a full
              indemnity basis) incurred, suffered or arising out of or in
              connection with any Content you post through the Website;
            </div>
            <div class="mt_10">
              (ii)any direct or indirect consequences of you accessing, using or
              transacting on the Website or attempts to do so and any breach by
              you or your agents of these Terms; and/or
            </div>
            <div class="mt_10">(iii)any breach of the Terms.</div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              14.Dispute Resolution
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              14.1. Compulsory
            </div>
            <div class="mt_10">
              If a dispute arises out of or relates to the Terms, either party
              may not commence any Tribunal or Court proceedings in relation to
              the dispute, unless the following clauses have been complied with
              (except where urgent interlocutory relief is sought).
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              14.2. Notice
            </div>
            <div class="mt_10">
              A party to the Terms claiming a dispute ('Dispute') has arisen
              under the Terms, must give written notice to the other party
              detailing the nature of the dispute, the desired outcome and the
              action required to settle the Dispute.
            </div>
            <div style="font-weight: 700; font-size: 17px">
              14.3. Resolution
            </div>
            <div class="mt_10">
              On receipt of that notice ('Notice') by that other party, the
              parties to the Terms ('Parties') must:
            </div>
            <div class="mt_10">
              (i)Within 21 days of the Notice endeavour in good faith to resolve
              the Dispute expeditiously by negotiation or such other means upon
              which they may mutually agree;
            </div>
            <div class="mt_10">
              (ii)If for any reason whatsoever, 21 days after the date of the
              Notice, the Dispute has not been resolved, the Parties must either
              agree upon selection of a mediator or request that an appropriate
              mediator be appointed by the President of the Law Institute of
              Victoria or his or her nominee;
            </div>
            <div class="mt_10">
              (iii)The Parties are equally liable for the fees and reasonable
              expenses of a mediator and the cost of the venue of the mediation
              and without limiting the foregoing undertake to pay any amounts
              requested by the mediator as a pre-condition to the mediation
              commencing. The Parties must each pay their own costs associated
              with the mediation;
            </div>
            <div class="mt_10">
              (iv)The mediation will be held in Melbourne, Australia.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              14.4. Confidential
            </div>
            <div class="mt_10">
              All communications concerning negotiations made by the Parties
              arising out of and in connection with this dispute resolution
              clause are confidential and to the extent possible, must be
              treated as "without prejudice" negotiations for the purpose of
              applicable laws of evidence.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              14.5. Termination of Mediation
            </div>
            <div class="mt_10">
              If 30 days have elapsed after the start of a mediation of the
              Dispute and the Dispute has not been resolved, either Party may
              ask the mediator to terminate the mediation and the mediator must
              do so.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              15.Use of Cookies
            </div>
            <div class="mt_10">
              (a)Cookies are small pieces of information that are stored by your
              browser on your computer’s hard drive. A cookie cannot read or
              collect personal identifiable information or any other data from
              your hard drive and takes up very little memory. MORRIS GROUP
              AUSTRALIA Pty Ltd may use cookies on its Sites, including its
              service providers, related companies and third parties such as
              analytics, advertising or ad serving partners. The information
              collected is aggregated from all MORRIS GROUP AUSTRALIA Pty Ltd’s
              customers to improve its Site by identifying how different areas
              of the site are viewed, used or utilised.
            </div>
            <div class="mt_10">
              You may choose to disable cookies on your web browser. However, by
              doing so, MORRIS GROUP AUSTRALIA Pty Ltd may not be able to
              provide you all features that is available to you on the Site.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              16.Venue and Jurisdiction
            </div>
            <div class="mt_10">
              The Purchase Services offered by MORRIS GROUP AUSTRALIA Pty Ltd
              are intended to be used by residents of Australia. In the event of
              any dispute arising out of or in relation to the Website, you
              agree that the exclusive venue for resolving any dispute shall be
              in the courts of Victoria, Australia.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              17.Governing Law
            </div>
            <div class="mt_10">
              The Terms are governed by the laws of Victoria, Australia. Any
              dispute, controversy, proceeding or claim of whatever nature
              arising out of or in any way relating to the Terms and the rights
              created hereby shall be governed, interpreted and construed by,
              under and pursuant to the laws of Victoria Australia, without
              reference to conflict of law principles, notwithstanding mandatory
              rules. The validity of this governing law clause is not contested.
              The Terms shall be binding to the benefit of the parties hereto
              and their successors and assigns.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              18.Independent Legal Advice
            </div>
            <div class="mt_10">
              Both parties confirm and declare that the provisions of the Terms
              are fair and reasonable and both parties having taken the
              opportunity to obtain independent legal advice and declare the
              Terms are not against public policy on the grounds of inequality
              or bargaining power or general grounds of restraint of trade.
            </div>
            <div class="mt_10" style="font-weight: 700; font-size: 17px">
              19.Severance
            </div>
            <div class="mt_10">
              If any part of these Terms is found to be void or unenforceable by
              a Court of competent jurisdiction, that part shall be severed and
              the rest of the Terms shall remain in force.
            </div>
            <div class="mt_10">
              For any questions and notices, please contact us at:
            </div>
            <div class="mt_10">
              MORRIS GROUP AUSTRALIA Pty Ltd (ACN 152 141 298) T/A Smart User,
              Mega Energy and Astep to Green ABC 12 152 141 298
            </div>
            <div class="mt_10" style="font-weight: 700">
              Email: info@smartuser.com.au
            </div>
            <div class="mt_10" style="font-weight: 700">
              Last update: 10 July 2023
            </div>

            <!-- <div class="header">
          <div class="top_logo">
            <img src="./img/mini_toplogo.jpg" style="height: 30px">
          </div>
        </div> -->
            <!-- <div class="home_form_item radius_top" style=" line-height: 30px;">
          <div>
            <div style="font-weight: 600; font-size: 17px; padding: 10px 20px">
              <span>Customer Information</span>
            </div>
          </div>
        </div>   -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      offsetwidth: document.documentElement.clientWidth,
    };
  },
  mounted() {},

  methods: {
    // goinfo() {
    //   // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");
    //   this.$emit("fun", "/CustomerDetails");
    // },
  },
};
</script>

<style scoped>
.special-link {
  text-decoration: underline;
}
a:link {
  color: blue;
}
/* 已访问的链接 */
a:visited {
  color: purple;
}

/* 鼠标悬浮在链接上 */
a:hover {
  color: red;
}

/* 链接被点击时 */
a:active {
  color: yellow;
}
.mt_20 {
  margin-top: 20px;
}
.mt_10 {
  margin-top: 10px;
}
.text_bold {
  font-weight: 700;
}
</style>
